import React, { useState, useEffect } from "react";
import { AcademicsMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import { BlocksRenderer } from "../../components/BlockRenderer";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData } from "../../utils/fetchData";
import { useParams } from "react-router-dom";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import ScrollToTop from "../../components/Common/ScrollTop";
import RightSidebar from "../../components/Common/RightSidebar";

const getChildPath = (type, setPath) => {
  let text = "Infrastructure and Learning Resources";
  if (!type) {
    setPath({
      pageCategory: "",
      pageName: "Infrastructure and Learning Resources",
    });
    return null;
  }
  if (type === "physical_facilities") text = "Physical Facilities";
  else if (type === "adequate_facilities") text = "Adequate Facilities";
  else if (type === "learning_resource") text = "Library Learning Resource";
  setPath({
    pageCategory: {
      label: "Infrastructure and Learning Resources",
      path: "infrastructurelearning",
    },
    pageName: text,
  });
  return null;
};
const ColLayout = () => {
  const [data, setData] = useState([]);
  const [path, setPath] = useState({
    pageCategory: "",
    pageName: "Infrastructure and Learning Resources",
  });
  const { type } = useParams();
  useEffect(() => {
    getChildPath(type, setPath);
    fetchData(JNTUK_API_Urls.Infrastructure_and_Learning_Resources, setData);
  }, [type]);

  return (
    <React.Fragment>
      <SiteBreadcrumb
        pageCategory={path.pageCategory}
        pageName={path.pageName}
      />
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 xl-mb-30">
              <div className="about-intro">
                <AcademicsMenu />
                <SideBar />
              </div>
            </div>

            <div className="col-xl-6 pl-20 xl-pl-14">
              <div className="content-wrapper">
                <div className="content-section">
                  <h3
                    style={{ marginBottom: "20px" }}
                    title="Infrastructure and Learning Resources"
                  >
                    Infrastructure and Learning Resources
                  </h3>
                  {(!type || type === "physical_facilities") && (
                    <BlocksRenderer
                      content={
                        data?.data?.attributes?.Physical_Facilities || []
                      }
                    />
                  )}
                  {(!type || type === "adequate_facilities") && (
                    <>
                      <BlocksRenderer
                        content={
                          data?.data?.attributes
                            ?.Adequate_Facilities_Cultural_Activities || []
                        }
                      />
                      <BlocksRenderer
                        content={
                          data?.data?.attributes
                            ?.Adequate_Facilities_General_Campus || []
                        }
                      />
                    </>
                  )}
                  {(!type || type === "learning_resource") && (
                    <BlocksRenderer
                      content={
                        data?.data?.attributes?.Library_Learning_Resource || []
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 pl-40 xl-pl-14">
              <RightSidebar />
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default ColLayout;
