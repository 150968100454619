import React, { useState, useEffect } from "react";
import { AboutMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import { BlocksRenderer } from "../../components/BlockRenderer";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData } from "../../utils/fetchData";
import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import RightSidebar from "../../components/Common/RightSidebar";

const SDDContent = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchData(JNTUK_API_Urls.SDD, setData);
  }, []);

  return (
    <React.Fragment>
      <SiteBreadcrumb pageName="SDD" />
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 xl-mb-30">
              <div className="about-intro">
                <AboutMenu />
                <SideBar />
              </div>
            </div>
            <div className="col-xl-6 pl-20 xl-pl-14">
              <div className="content-wrapper">
                <h3 className="heading" title={data?.data?.attributes?.Page_Heading}>
                  {data?.data?.attributes?.Page_Heading}
                </h3>
                <div className="content-section">
                  <BlocksRenderer
                    content={data?.data?.attributes?.Body || []}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3 pl-40 xl-pl-14">
              <RightSidebar />
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default SDDContent;
