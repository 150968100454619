import React from "react";
import { Link, useLocation } from "react-router-dom";

const SiteBreadcrumb = (props) => {
  const { pageCategory, pageName } = props;
  let location = useLocation();
  const currentDomain = location.pathname.split("/")[1];
  const isItDomain = (currentDomain) => {
    const domains = ["dap", "da","de","drd","dirp","dics","dfaam","dfd","sdc","wec"];
    return domains.includes(currentDomain);
  };
  return (
    <div className="breadcrumb-bg">
      <div className="container-fluid">
        <div className={"breadcrumbs-text white-color"}>
          <ul>
            <li>
              <Link
                to={isItDomain(currentDomain) ? `/${currentDomain}` : "/"}
                className="active"
              >
                {"Home"}
              </Link>
            </li>
            {pageCategory && (
              <>
                <li>
                  <Link to={`/${pageCategory?.path}`} className="active">
                    {pageCategory ? pageCategory?.label : "Category"}
                  </Link>
                </li>
                <li>{pageName ? pageName : "Page Name"}</li>
              </>
            )}
            {!pageCategory && <li>{pageName ? pageName : "Page Name"}</li>}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SiteBreadcrumb;
