import React from "react";
import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";

const Content = () => {

  return (
    <React.Fragment>
      <SiteBreadcrumb
        pageCategory=""
        pageName="Website Privacy Policy"
      />
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="content-wrapper">
                <div className="content-section">
                  <h3 title="Website Privacy Policy">Website Privacy Policy</h3>
                  <hr></hr>
                  <div className="pageContent">
                    <p>
                      As a general rule, this website  Jawaharlal Nehru Technological University, Anantapur Jntua.edu.in - does not collect Personal Information about you when you visit the site. You can generally visit the site without revealing Personal Information, unless you choose to provide such information.
                    </p>
                    <p>
                      Your email address will only be recorded if you choose to send a message. It will only be used for the purpose for which you have provided it and will not be added to a mailing list. Your email address will not be used for any other purpose, and will not be disclosed, without your consent.
                    </p>
                    <p>
                      If you are asked for any other Personal Information you will be informed how it will be used if you choose to give it. If at any time you believe the principles referred to in this privacy statement have not been followed, or have any other comments on these principles, please notify the webmaster through the contact us page.
                    </p>
                    <p>
                      <b>Note:</b> The use of the term "Personal Information" in this privacy statement refers to any information from which your identity is apparent or can be reasonably ascertained.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default Content;
