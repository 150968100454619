import React from "react";
import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";

const Content = () => {

  return (
    <React.Fragment>
      <SiteBreadcrumb
        pageCategory=""
        pageName="Terms & Conditions"
      />
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="content-wrapper">
                <div className="content-section">
                  <h3>Terms & Conditions of Use</h3>
                  <p>Jawaharlal Nehru Technological University Anantapur (JNTUA) is established in the year 2008 vide ACT NO. 30 of 2008 by the State of Andhra Pradesh. </p>
                  <hr></hr>
                  <div className="pageContent">
                    <h4>By accessing this website you accept and agree to the following terms and conditions:</h4>
                    <p>
                      Jawaharlal Nehru Technological University Anantapur (JNTUA)  gives no warranty as to the accuracy of the information or guidance for any specific purpose. The information and guidance contained on the website does not constitute legal or professional advice. All implied warranties and conditions are excluded, to the maximum extent permitted by law.
                    </p>
                    <p>
                      JNTUA is in no way responsible or liable for any damage or loss whatsoever arising from viewing or downloading information from this website and from the use of, or reliance on, the information and guidance on this website although this does not affect claims in respect of death or personal injury caused by negligence.
                    </p>
                    <p>
                      Any links to third parties websites are provided for convenience only. JNTUA has no control over such websites and accepts no liability for their content.
                    </p>
                    <p>
                      JNTUA reserves the right at all times to vary, change, alter, amend or remove any of these terms. By browsing the website you accept that you are bound by the current terms and conditions and notices, and we therefore recommend that you check these each time you visit the site.
                    </p>
                    <p>
                      <b>You must not use this website, and/or any interactive facilities which are available through it to:</b>
                    </p>
                    <ul>
                      <li>
                        Commit or encourage unlawful acts or in breach of these terms of use.
                      </li>
                      <li>Misrepresent your identity.</li>
                      <li>Hack, or attempt to hack, any part of the website or any system used to run it.</li>
                      <li>Store personal data derived from the website.</li>
                      <li>Post material that is obscene, offensive, or otherwise unlawful.</li>
                      <li>Technically harm the website and/or its systems (including, without limitation, computer viruses or malicious software or harmful data).</li>
                    </ul>
                    <p>
                      While JNTUA tries to make this website available 24 hours a day, it reserves the right to suspend or withdraw access, without notice, to either the whole or part of the website, for any reason.
                    </p>
                    <h4>Copyright:</h4>
                    <p>This site and its content are protected by copyright. The content of this website may be viewed, printed or downloaded for personal, non-commercial use only. No information should be modified, reproduced, transmitted, published or otherwise made available in whole or in part without the prior written consent of the JNTUA.</p>
                    <p>In no event shall any user of this website use the name or logo of JNTUA or any of its trademarks, other than with the express written consent of the authorised representative of JNTUA.</p>
                    <h4>
                      Complaints:
                    </h4>
                    <p>Any complaint regarding any content on JNTUA website or information relating to the institute contained on external Internet sites will be addressed at the earliest.</p>
                    <p>
                      <b>Please use our feedback form to contact us:</b>
                    </p>
                    <p>These terms and condition represent the entire agreement between the parties in regard to the use of the website. It is the sole discretion of the institute to alter or amend the website and the terms and conditions without notice. Information contained on the website may be subject to conditions and/or disclaimers as indicated.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default Content;
