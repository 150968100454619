import React from 'react';
import { Link } from 'react-router-dom';

// Categories Icon
import icon1 from '../../../assets/img/accreditation/1.png';
import icon2 from '../../../assets/img/accreditation/2.png';
import icon3 from '../../../assets/img/accreditation/3.png';
import icon4 from '../../../assets/img/accreditation/4.png';
import icon5 from '../../../assets/img/accreditation/5.png';
import icon6 from '../../../assets/img/accreditation/6.png';
import icon7 from '../../../assets/img/accreditation/7.png';


const Accreditation = () => {
   const externalLink = (url) => {
      const userConfirmed = window.confirm(
        "You are being redirected to an external website. Please note that JNTUA Portal is not responsible for external websites content & privacy policies."
      );
      
      if (userConfirmed) {
        window.open(url, "_blank");
      } else {
  
      }
    };
   return (
      <div className="rs-subject style1 bg-white pb-20 md-pb-40" style={{background: "#fcfaed"}}>
         <div className='divider mb-30'></div>
         <div className="container accreditation">
            <div className="row justify-content-center row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-7">
               <div className="mb-30 text-center">
                  <Link  onClick={() => externalLink("https://www.ugc.gov.in")}>
                     <img src={icon1}  alt="UGC" title="UGC"/>
                  </Link>
               </div>
               <div className="mb-30 text-center">
                  <Link  onClick={() => externalLink("https://www.aicte-india.org")}>
                     <img src={icon2} alt="AICTE" title="AICTE"/>
                  </Link>
               </div>
               <div className="mb-30 text-center">
                  <Link  onClick={() => externalLink("https://apsche.ap.gov.in")}>
                     <img src={icon3} alt="APSCHE" title="APSCHE" />
                  </Link>
               </div>
               <div className="mb-30 text-center">
                  <Link  onClick={() => externalLink("http://naac.gov.in/index.php/en")}>
                     <img src={icon4} alt="NAAC" title="NAAC" />
                  </Link>
               </div>
               <div className="mb-30 text-center">
                  <Link  onClick={() => externalLink("https://www.nirfindia.org")}>
                     <img src={icon5} alt="NIRFINDIA" title="NIRFINDIA" />
                  </Link>
               </div>
               <div className="mb-30 text-center">
                  <Link  onClick={() => externalLink("https://www.education.gov.in")}>
                     <img src={icon6} alt="Ministry of Education" title="Ministry of Education" />
                  </Link>
               </div>
               <div className="mb-30 text-center">
                  <Link  onClick={() => externalLink("https://www.ap.gov.in/")}>
                     <img src={icon7} alt="Emblem of Andhra Pradesh" title="Emblem of Andhra Pradesh" />
                  </Link>
               </div>
            </div>
         </div>
      </div>
   );
}

export default Accreditation;