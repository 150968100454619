import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import ScrollToTop from "../../components/Common/ScrollTop";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SearchModal from "../../components/Layout/Header/SearchModal";
// Image
import footerLogo from "../../assets/img/logo/jntua-logo.png";

import { BlocksRenderer } from "../../components/BlockRenderer";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData, imageBaseURL } from "../../utils/fetchData";
import Accreditation from "../../components/Common/Accreditation";
import { AboutMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import RightSidebar from "../../components/Common/RightSidebar";
import { useFontSize } from '../../FontSizeContext';

const About = () => {
  const [data, setData] = useState([]);
  const { fontSize } = useFontSize();
  useEffect(() => {
    fetchData(JNTUK_API_Urls.About_JNTUK, setData);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>About Jawaharlal Nehru Technological University | JNTU Anantapur</title>
        <meta name="description" content="Jawaharlal Nehru Technological University, Anantapur is a public university, in Anantapur, East Godavari district, in Andhra Pradesh state" />
        <meta name="keywords" content="About JNTUK, About Jawaharlal Nehru Technological University Anantapur"></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="home"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />
      <style>
        {`
                    body,
                    .rs-latest-events.style1 .event-wrap .events-short .date-part .date,
                    .widget ul li a {
                        font-size: ${fontSize}px;
                    }
                    .rs-inner-blog .blog-item .blog-content .blog-title,
                    .widget h1{
                        font-size: calc(${fontSize}px + 10px);
                    }
                    .breadcrumb-bg .breadcrumbs-text ul li{
                      font-size: calc(${fontSize}px + 2px);
                    }
                    
                    .full-width-header .rs-header .topbar-area .topbar-right li.login-register a,
                    .readon.orange-btn, .rs-latest-events.bg-wrap .notification-filter button,
                    .rs-latest-events.style1 .event-wrap:not(.ls-area) .events-short .slNo, 
                    .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > a,
                    .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > ul li > a{                       
                        font-size: calc(${fontSize}px - 2px);
                    }

                    .rs-footer .footer-top-social .social_msg{
                        font-size: calc(${fontSize}px + 18px);
                    }
                    .rs-footer .footer-center h3{
                        font-size: calc(${fontSize}px + 12px);
                    }
                    .rs-footer.home9-style .footer-top .widget-title,
                    .rs-team.style1 .about-btn--primary,
                    .rs-team.style1 .about-btn--secondary{
                        font-size: calc(${fontSize}px + 4px);
                    }
                    .rs-footer .footer-center h4,
                    .rightBar h4{
                        font-size: calc(${fontSize}px + 6px);
                    }
                    
                `}
      </style>

      <div className="pageTopOffset">
        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
          pageTitle="About JNTUA"
          pageName="About JNTUA"
          breadcrumbsClass="breadcrumb-bg"
        />
        {/* breadcrumb-area-start */}

        <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 xl-mb-30">
                <div className="about-intro">
                  <AboutMenu />
                  <SideBar />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="row">
                  <div className="rs-inner-blog col-lg-12">
                    <div className="blog-item">
                      <div className="blog-img mb-20">
                        {data?.data && (
                          <img
                            src={
                              imageBaseURL +
                              data?.data?.attributes?.Image?.data?.attributes
                                ?.url || ""
                            }
                            title={data?.data?.attributes?.Image?.data?.attributes.alternativeText}
                            alt={data?.data?.attributes?.Image?.data?.attributes.alternativeText}
                          />
                        )}
                      </div>
                      <div className="blog-content">
                        <h3 className="blog-title" title={data?.data?.attributes?.Title || ""}>
                          {data?.data?.attributes?.Title || ""}
                        </h3>
                        <BlocksRenderer
                          content={data?.data?.attributes?.Body || []}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 pl-40 xl-pl-14">
                <RightSidebar />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Accreditation />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
};

export default About;
