import React, { useState, useEffect } from "react";
import ScrollToTop from "../../../../components/Common/ScrollTop";
import { useLocation } from "react-router-dom";
import { fetchData, imageBaseURL } from "../../../../utils/fetchData";
import {
  getAPIPath,
  getMenu,
  getPageTitle,
} from "../../../../utils/getDomainData";
import SliderDefault from "../../../../components/Slider/SliderDefault";
import { BlocksRenderer } from "../../../../components/BlockRenderer";

const HomeMain = () => {
  const [data, setData] = useState([]);
  const [profileData, SetProfileData] = useState(null);
  let location = useLocation();
  const currentDomain = location.pathname.split("/")[1];
  useEffect(() => {
    fetchData(getAPIPath(currentDomain)?.home, setData);
    fetchData(getAPIPath(currentDomain)?.profile, SetProfileData);
  }, [currentDomain]);

  return (
    <React.Fragment>
      <SliderDefault />
      {getMenu(currentDomain)}

      <div id="rs-about" className="rs-about style1 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col md-mb-30">
              <div className="content-wrapper">
                <div className="content-section">
                  <div className="row flex-column flex-md-row">
                    <div
                      className="col-lg-2 col-6 mx-auto md-mb-30">
                      {profileData?.data?.attributes?.jntuk_directorate
                        ?.data !== null && (
                        <>
                          <div className="img-part">
                            <img
                              className="about-main w-100"
                              src={
                                imageBaseURL +
                                profileData?.data?.attributes?.jntuk_directorate
                                  ?.data?.attributes?.Image?.data?.attributes
                                  ?.url
                              }
                              alt={
                                profileData?.data?.attributes?.jntuk_directorate
                                  ?.data?.attributes?.Name
                              }
                              title={
                                profileData?.data?.attributes?.jntuk_directorate
                                  ?.data?.attributes?.Name
                              }
                            />
                            <div className="info-part text-center mt-14">
                              <h4
                                className="white"
                                title={
                                  profileData?.data?.attributes
                                    ?.jntuk_directorate?.data?.attributes?.Name
                                }
                              >
                                {
                                  profileData?.data?.attributes
                                    ?.jntuk_directorate?.data?.attributes?.Name
                                }
                              </h4>
                              <div className="exInfo">
                                {
                                  profileData?.data?.attributes
                                    ?.jntuk_directorate?.data?.attributes
                                    ?.Designation
                                }
                              </div>
                              <div className="exInfo">
                                {getPageTitle(currentDomain)}
                              </div>
                            </div>
                          </div>
                          <div style={{ marginTop: "20px", textAlign: "center" }}>
                            <a
                              href={`${getAPIPath(currentDomain)?.profileLink}`}
                            >
                              View Profile
                            </a>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={profileData?.data?.attributes?.jntuk_directorate
                        ?.data== null?"":"col-lg-10"}>
                      <div className="about-content">
                        <h2
                          style={{ marginBottom: "20px" }}
                          title={data?.data?.attributes?.Page_Heading}
                        >
                          {data?.data?.attributes?.Page_Heading}
                        </h2>
                        <BlocksRenderer
                          content={data?.data?.attributes?.Body || []}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ScrollToTop scrollClassName="scrollup secondaryColor" />
    </React.Fragment>
  );
};
export default HomeMain;
