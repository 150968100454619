import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { imageBaseURL } from "../../../../utils/fetchData";
const GalleryPart = ({ title, images }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [photos2, setPhotos2] = useState([]);
  React.useEffect(() => {
    if (images?.data?.length > 0) {
      const data = [];
      images?.data?.map((obj) => {
        if (obj?.attributes?.formats?.thumbnail)
          data.push({
            src: imageBaseURL + obj?.attributes?.formats?.thumbnail?.url,
          });
        else data.push({ src: imageBaseURL + obj?.attributes?.url });
      });
      setPhotos(data);
    }
  }, [images]);
  React.useEffect(() => {
    if (images?.data?.length > 0) {
      const data = [];
      images?.data?.map((obj) => {
        if (obj?.attributes)
          data.push({
            src: imageBaseURL + obj?.attributes?.url,
          });
        else data.push({ src: imageBaseURL + obj?.attributes?.url });
      });
      setPhotos2(data);
    }
  }, [images]);
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <>
      <div className="rs-gallery pb-30 md-pt-80 md-pb-50">
        <div className="">
          <h3 className="blog-title" title={title}>{title}</h3>
          <Gallery direction={"row"} photos={photos} onClick={openLightbox} />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photos2?.map((x) => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </div>
    </>
  );
};

export default GalleryPart;
