import React from "react";
import teamImg2 from "../../../assets/img/about/nehru02.jpg";
import imgplaceholder from "../../../assets/img/image-placeholder.jpg"

function RightSidebar(props) {
  return (
    <div className="rightBar">
      <div className="rBlock">
        <img src={teamImg2} alt="Pandit Jawaharlal Nehru" title="Pandit Jawaharlal Nehru" className="w-100 mb-10" />
        <h4 title="Pandit Jawaharlal Nehru">Pandit Jawaharlal Nehru</h4>
        <p>
          A University Stands for Humanism, Tolerance, Reason, Progress, The Adventure of Ideas and Search for The Truth.
        </p>
      </div>
      <div className="rBlock">
        <img src={imgplaceholder} alt="" title="" className="w-100 mb-10" />
        <h4 title="Anantapur Port">Title</h4>
        <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
        </p>
      </div>
    </div>
  );
}

export default RightSidebar;
