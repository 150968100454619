import axios from "./axios.config"
export const imageBaseURL=process.env.REACT_APP_JNTUA_APIS_IMAGE_BASE_URL;
export async function fetchData(url,setData){
    const {data} = await axios.get(url)
    setData(data)
    return data
};


export const Get_JNTUA_PDF_Links = async () => {
    const data = await axios.get(`/jntua-website-pdf-link`);
    console.log("testing25......", data?.data?.data?.attributes);
    return data?.data?.data?.attributes;
  };

  export const Get_DRD_PDF_Links = async () => {
    const data = await axios.get(`/research-and-development-pdf-link`);
    return data?.data?.data?.attributes;
  };
  export const Get_SR_PDF_Links = async () => {
    const data = await axios.get(`/sponsored-research-file-url`);
    return data?.data?.data?.attributes;
  };
