import React from "react";
import { Link } from "react-router-dom";

function SideBar(props) {
  return (
    <div className="row rs-team style1 orange-color transparent-bg clearfix d-none d-xl-block">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="about-top-border"></div>
        {/* <div className="">
          <img src={teamImg2} alt="Jawaharlal Nehru" title="Jawaharlal Nehru" className="w-100"/>
        </div> */}
        <Link to="/profile/vice-chancellor" className="about-btn--primary">
          <span>Vice Chancellor</span>
        </Link>
        <Link to="/directorates" className="about-btn--secondary">
          <span>JNTUA Directorates</span>
        </Link>
        <Link to="/category/Latest-News" className="about-btn--secondary">
          <span>Notification Center</span>
        </Link>
      </div>
    </div>
  );
}

export default SideBar;
