import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../../../components/Layout/Header/Header";
import Footer from "../../../../components/Layout/Footer/Footer";
import { useLocation } from "react-router-dom";
import ScrollToTop from "../../../../components/Common/ScrollTop";
import OffWrap from "../../../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../../../components/Common/Breadcumb";
import SearchModal from "../../../../components/Layout/Header/SearchModal";
// Image
import footerLogo from "../../../../assets/img/logo/jntua-logo.png";
import { fetchData } from "../../../../utils/fetchData";
import { BlocksRenderer } from "../../../../components/BlockRenderer";
import {
  getAPIPath,
  getMenu,
  getPageTitle,
} from "../../../../utils/getDomainData";
const DWEC_Notifications = () => {
  const [data, setData] = useState([]);
  let location = useLocation();
  const currentDomain = location.pathname.split("/")[1];
  useEffect(() => {
    fetchData(getAPIPath(currentDomain)?.notifications, setData);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Notifications – {getPageTitle(currentDomain)}</title>
        <meta name="description" content="" />
        <meta name="keywords" content=""></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="home"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />
      <div className="pageTopOffset">
        <SiteBreadcrumb
          pageTitle="Notifications"
          pageName="Notifications"
          breadcrumbsClass="breadcrumb-bg"
        />
        {getMenu(currentDomain)}

        <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 md-mb-30">
                <div className="content-wrapper">
                  <div className="content-section">
                    <h3 title="Programmes">
                      {data?.data?.attributes?.Page_Heading}
                    </h3>
                    <BlocksRenderer
                      content={data?.data?.attributes?.Body || []}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <ScrollToTop scrollClassName="scrollup secondaryColor" />
      <SearchModal />
    </React.Fragment>
  );
};

export default DWEC_Notifications;
