import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import ScrollToTop from "../../components/Common/ScrollTop";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SearchModal from "../../components/Layout/Header/SearchModal";
import footerLogo from "../../assets/img/logo/jntua-logo.png";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData } from "../../utils/fetchData";
import Accreditation from "../../components/Common/Accreditation";
import { AboutMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import RightSidebar from "../../components/Common/RightSidebar";
import Pagination from "../../components/Pagination";

const E_Magazine = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(25);
  const totalItems = data?.meta?.pagination?.total;

  useEffect(() => {
    fetchData(
      JNTUK_API_Urls.E_Magazine + `&pagination[page]=${currentPage}`,
      setData
    );
  }, [currentPage]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "long", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Helmet>
        <title>E-Magazines | JNTU Anantapur</title>
        <meta
          name="description"
          content="E-Magazines of Jawaharlal Nehru Technological University Anantapur"
        />
        <meta
          name="keywords"
          content="E-Magazines of Jawaharlal Nehru Technological University Anantapur"
        ></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="home"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />
      <style>
        {`
          /* Your CSS styles here */
        `}
      </style>
      <div className="pageTopOffset">
        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
          pageTitle="E-Magazines"
          pageName="E-Magazines"
          breadcrumbsClass="breadcrumb-bg"
        />
        {/* breadcrumb-area-start */}

        <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 xl-mb-30">
                <div className="about-intro">
                  <AboutMenu />
                  <SideBar />
                </div>
              </div>
              <div className="col-xl-6 pl-20 xl-pl-14">
                <div className="row">
                  <div className="rs-inner-blog col-lg-12">
                    <h3 className="college-title">E-Magazines</h3>
                    <div className="overflow-x">
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Month/Year</th>
                            <th scope="col">Link</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.data?.map((obj, index) => (
                            <tr key={obj.id}>
                              <td>
                                {((currentPage - 1) * itemsPerPage + index + 1)
                                  .toString()
                                  .padStart(2, "0")}
                                .
                              </td>
                              <td>{formatDate(obj?.attributes?.Date)}</td>
                              <td>
                                <a
                                  href={obj?.attributes?.Link}
                                  className="d-flex flex-column align-items-center"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Click here
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      paginate={paginate}
                      arrayLength={totalItems / itemsPerPage}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 pl-40 xl-pl-14">
                <RightSidebar />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Accreditation />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
};

export default E_Magazine;
