import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import ScrollToTop from "../../components/Common/ScrollTop";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SearchModal from "../../components/Layout/Header/SearchModal";
import footerLogo from "../../assets/img/logo/jntua-logo.png";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData, imageBaseURL } from "../../utils/fetchData";
import Accreditation from "../../components/Common/Accreditation";
import { AboutMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import { useParams } from "react-router-dom";
import profIcon from "../../assets/img/profile-icon.png";
import { useFontSize } from "../../FontSizeContext";
import { Link } from "react-router-dom";
function convertUrl(inputString) {
  let stringWithUnderscores = inputString.replace(/-/g, " ");
  stringWithUnderscores = stringWithUnderscores.replace(/_/g, "&");

  return stringWithUnderscores;
}
const Faculty = () => {
  const [data, setData] = useState([]);
  const { fontSize } = useFontSize();
  const { type } = useParams();
  useEffect(() => {
    if (type)
      fetchData(
        JNTUK_API_Urls.Our_Faculty +
          `&filters[Department][$contains]=${
            convertUrl(type) || ""
          }&pagination[limit]=1000`,
        setData
      );
    else
      fetchData(
        JNTUK_API_Urls.Our_Faculty + `&pagination[limit]=1000`,
        setData
      );
  }, [type]);

  function getPath(inputString) {
    return inputString.split(" ").join("-");
  }
  const ProfileCard = ({ obj }) => {
    return (
      <div className="col-md-4 col-sm-6 text-center mb-3">
        <div style={styles.card}>
          <img
            src={
              obj?.attributes?.Image.data
                ? imageBaseURL + obj?.attributes?.Image.data.attributes.url
                : profIcon
            }
            alt="Profile Img"
            style={styles.image}
          />
          <div style={styles.content} className="faculty-content">
            <p>
              <b>{obj?.attributes?.Name || ""}</b>
            </p>
            <p>{obj?.attributes?.Designation || ""}</p>
            {obj?.attributes?.Mobile && (
              <p>Mobile: {obj?.attributes?.Mobile || ""}</p>
            )}
            {obj?.attributes?.Mail && (
              <a href={`mailto:${obj?.attributes?.Mail}`}>
                Mail: {obj?.attributes?.Mail || ""}
              </a>
            )}
            {obj?.attributes?.Vidwan_Profile && (
              <a
                href={obj?.attributes?.Vidwan_Profile}
                target="_blank"
                rel="noreferrer"
              >
                Vidwan Profile
              </a>
            )}
            <p style={{ marginTop: "5px" }}>
              <b>Department/Centre/School</b>
            </p>
            <Link to={`/our-faculty/${getPath(obj?.attributes?.Department)}`}>
              {obj?.attributes?.Department || ""}
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const styles = {
    card: {
      border: "1px solid #ccc",
      borderRadius: "8px",
      padding: "16px",
      width: "100%",
      height: "100%",
    },
    image: {
      width: "200px",
      height: "200px",
      marginBottom: "16px",
      objectFit: "cover"
    },
    content: {
      display: "flex",
      flexDirection: "column",
    },
    name: {
      margin: "0",
      fontSize: "1.25em",
    },
    text: {
      margin: "4px 0",
    },
    link: {
      color: "#007bff",
      textDecoration: "none",
    },
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Our Faculty | JNTU Anantapur</title>
        <meta
          name="description"
          content="JNTUA Faculty Jawaharlal Nehru Technological University Anantapur"
        />
        <meta
          name="keywords"
          content="JNTUA Faculty Jawaharlal Nehru Technological University Anantapur"
        ></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="home"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />
      <style>
        {`
          body,
          .rs-latest-events.style1 .event-wrap .events-short .date-part .date,
          .widget ul li a {
              font-size: ${fontSize}px;
          }
          .rs-inner-blog .blog-item .blog-content .blog-title,
          .widget h1,.content-wrapper h2, .heading, .college-title{
              font-size: calc(${fontSize}px + 10px);
          }
          .breadcrumb-bg .breadcrumbs-text ul li{
            font-size: calc(${fontSize}px + 2px);
          }
          
          .full-width-header .rs-header .topbar-area .topbar-right li.login-register a,
          .readon.orange-btn, .rs-latest-events.bg-wrap .notification-filter button,
          .rs-latest-events.style1 .event-wrap:not(.ls-area) .events-short .slNo, 
          .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > a,
          .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > ul li > a,
          table th, table td {                       
              font-size: calc(${fontSize}px - 2px);
          }

          .rs-footer .footer-top-social .social_msg{
              font-size: calc(${fontSize}px + 18px);
          }
          .rs-footer .footer-center h3{
              font-size: calc(${fontSize}px + 12px);
          }
          .rs-footer.home9-style .footer-top .widget-title,
          .rs-team.style1 .about-btn--primary,
          .rs-team.style1 .about-btn--secondary,
          .course-overview .inner-box h5{
              font-size: calc(${fontSize}px + 4px);
          }
          .rs-footer .footer-center h4,
          .rightBar h4{
              font-size: calc(${fontSize}px + 6px);
          }
          
      `}
      </style>
      <div className="pageTopOffset">
        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
          pageTitle="Our Faculty"
          pageName="Our Faculty"
          breadcrumbsClass="breadcrumb-bg"
        />
        {/* breadcrumb-area-start */}
        <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 xl-mb-30">
                <div className="about-intro">
                  <AboutMenu />
                  <SideBar />
                </div>
              </div>
              <div className="col-lg-9 pl-20 md-pl-14">
                <div className="row">
                  <div className="rs-inner-blog col-lg-12">
                    <h3 className="college-title" title="Our Faculty">
                      Our Faculty
                    </h3>
                    <div className="row">
                      {data?.data?.map((obj) => (
                        <ProfileCard obj={obj} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Accreditation />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
};

export default Faculty;
