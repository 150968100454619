import React, { useState, useEffect } from "react";
import { AccreditationMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import { BlocksRenderer } from "../../components/BlockRenderer";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData } from "../../utils/fetchData";
import { useParams } from "react-router-dom";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import ScrollToTop from "../../components/Common/ScrollTop";
import RightSidebar from "../../components/Common/RightSidebar";

const getChildPath = (type, setPath) => {
  let text = "IQAC";
  if (!type) {
    setPath({ pageCategory: "", pageName: "IQAC" });
    return null;
  }
  if (type === "about") text = "About";
  else if (type === "functions") text = "Functions";
  else if (type === "benefits") text = "Benefits";
  else if (type === "important-initiatives") text = "Important Initiatives";
  setPath({ pageCategory: { label: "IQAC", path: "iqac" }, pageName: text });
  return null;
};
const ColLayout = () => {
  const [data, setData] = useState([]);
  const [path, setPath] = useState({ pageCategory: "", pageName: "IQAC" });
  const { type } = useParams();
  useEffect(() => {
    getChildPath(type, setPath);
    fetchData(JNTUK_API_Urls.IQAC, setData);
  }, [type]);

  return (
    <React.Fragment>
      <SiteBreadcrumb
        pageCategory={path.pageCategory}
        pageName={path.pageName}
      />
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 xl-mb-30">
              <div className="about-intro">
                <AccreditationMenu />
                <SideBar />
              </div>
            </div>

            <div className="col-xl-6 pl-20 xl-pl-14">
              <div className="content-wrapper">
                <div className="content-section">
                  <h3 style={{ marginBottom: "20px" }} title="IQAC">
                    IQAC
                  </h3>

                  {(!type || type === "about") && (
                    <>
                      <h4>About</h4>
                      <BlocksRenderer
                        content={data?.data?.attributes?.About_IQAC || []}
                      />
                    </>
                  )}

                  {(!type || type === "functions") && (
                    <>
                      <h4>Functions of IQAC</h4>
                      <BlocksRenderer
                        content={
                          data?.data?.attributes?.Functions_of_IQAC || []
                        }
                      />
                    </>
                  )}
                  {(!type || type === "benefits") && (
                    <>
                      <h4>Benefits of IQAC</h4>
                      <BlocksRenderer
                        content={data?.data?.attributes?.Benefits_of_IQAC || []}
                      />
                    </>
                  )}
                  {(!type || type === "important-initiatives") && (
                    <>
                      <h4>
                        Important Initiatives Started at J.N.T. University
                        Anantapur
                      </h4>
                      <BlocksRenderer
                        content={
                          data?.data?.attributes?.Important_Initiatives || []
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 pl-40 xl-pl-14">
              <RightSidebar />
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default ColLayout;
