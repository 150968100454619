import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useWindowSize from "../../../useWindowSize";
import {
  Get_DRD_PDF_Links,
  Get_JNTUA_PDF_Links,
  Get_SR_PDF_Links,
} from "../../../utils/fetchData";

export const DAPMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className="department-menu">
      <div className="container">
        <div className="d-xl-none text-white align-items-center py-3 d-flex justify-content-between">
          Menu
          <span
            className="fad fa-list-ul"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </div>
        {internalMenuOpen && (
          <ul>
            <li>
              <Link to={"/dap"}>Home</Link>
            </li>
            <li>
              <Link to={"/dap/about-directorate"}>About</Link>
            </li>
            <li className="hasSubmenu">
              <Link to="#">Courses Offered</Link>
              <ul>
                <li>
                  <Link to={"/dap/ug-programmes"}>UG Programmes</Link>
                </li>
                <li className="hasSubmenu">
                  <Link to="#">PG Programmes</Link>
                  <ul>
                    <li>
                      <Link to={"/dap/regular-courses"}>Regular Courses</Link>
                    </li>
                    <li>
                      <Link to={"/dap/part-time"}>Part Time</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to={"/dap/integrated-dual-degree-programmes"}>
                    Integrated Dual Degree
                  </Link>
                </li>
                <li>
                  <Link to={"/dap/mous-list"}>MoU's List</Link>
                </li>
                <li>
                  <Link to={"/dap/research-programmes"}>
                    Research Programmes
                  </Link>
                </li>
              </ul>
            </li>
            <li className="hasSubmenu">
              <Link to="#">Curriculum</Link>
              <ul>
                <li className="hasSubmenu">
                  <Link to={"#"}>UG</Link>
                  <ul>
                    <li className="hasSubmenu">
                      <Link to={"#"}>B.Tech</Link>
                      <ul>
                        <li>
                          <Link to={"/dap/curriculum/regulations/B.Tech"}>
                            Regulations
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              "/dap/curriculum/course-structure-syllabi/B.Tech"
                            }
                          >
                            Course Structure & Syllabi
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="hasSubmenu">
                      <Link to={"#"}>B.Pharmacy</Link>
                      <ul>
                        <li>
                          <Link to={"/dap/curriculum/regulations/B.Pharmacy"}>
                            Regulations
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              "/dap/curriculum/course-structure-syllabi/B.Pharmacy"
                            }
                          >
                            Course Structure & Syllabi
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="hasSubmenu">
                  <Link to={"#"}>PG</Link>
                  <ul>
                    <li className="hasSubmenu">
                      <Link to={"#"}>M.Tech</Link>
                      <ul>
                        <li>
                          <Link to={"/dap/curriculum/regulations/M.Tech"}>
                            Regulations
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              "/dap/curriculum/course-structure-syllabi/M.Tech"
                            }
                          >
                            Course Structure & Syllabi
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="hasSubmenu">
                      <Link to={"#"}>M.Pharmacy</Link>
                      <ul>
                        <li>
                          <Link to={"/dap/curriculum/regulations/M.Pharmacy"}>
                            Regulations
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              "/dap/curriculum/course-structure-syllabi/M.Pharmacy"
                            }
                          >
                            Course Structure & Syllabi
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="hasSubmenu">
                      <Link to={"#"}>MBA</Link>
                      <ul>
                        <li>
                          <Link to={"/dap/curriculum/regulations/MBA"}>
                            Regulations
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/dap/curriculum/course-structure-syllabi/MBA"}
                          >
                            Course Structure & Syllabi
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="hasSubmenu">
                      <Link to={"#"}>MCA</Link>
                      <ul>
                        <li>
                          <Link to={"/dap/curriculum/regulations/MCA"}>
                            Regulations
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/dap/curriculum/course-structure-syllabi/MCA"}
                          >
                            Course Structure & Syllabi
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="hasSubmenu">
                      <Link to={"#"}>Pharm.D, Pharm.D(PB)</Link>
                      <ul>
                        <li>
                          <Link
                            to={
                              "/dap/curriculum/regulations/Pharm.D, Pharm.D(PB)"
                            }
                          >
                            Regulations
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              "/dap/curriculum/course-structure-syllabi/Pharm.D, Pharm.D(PB)"
                            }
                          >
                            Course Structure & Syllabi
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="hasSubmenu">
                      <Link to={"#"}>M.Sc</Link>
                      <ul>
                        <li>
                          <Link to={"/dap/curriculum/regulations/M.Sc"}>
                            Regulations
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/dap/curriculum/course-structure-syllabi/M.Sc"}
                          >
                            Course Structure & Syllabi
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="hasSubmenu">
              <Link to="#">News Bulletin</Link>
              <ul>
                <li>
                  <Link to={"/dap/category/circulars"}>Circulars</Link>
                </li>
                <li>
                  <Link to={"/dap/category/uncategorized"}>Uncategorized</Link>
                </li>
                <li>
                  <Link to={"/dap/downloads"}>Downloads</Link>
                </li>
              </ul>
            </li>

            <li className="ms-lg-auto hasSubmenu">
              <a href="javascript:void(0)">More</a>
              <ul>
                <li>
                  <Link to={"/dap/newsletter"}>Newsletter</Link>
                </li>
                <li>
                  <Link to={"/dap/academic-senate"}>Academic Senate</Link>
                </li>
                <li>
                  <Link to={"/dap/gallery"}>Gallery</Link>
                </li>
                <li>
                  <Link to={"/dap/contact-us"}>Contact Us</Link>
                </li>
              </ul>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export const DAMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className="department-menu">
      <div className="container">
        <div className="d-xl-none text-white align-items-center py-3 d-flex justify-content-between">
          Menu
          <span
            className="fad fa-list-ul"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </div>
        {internalMenuOpen && (
          <ul>
            <li>
              <Link to={"/da"}>Home</Link>
            </li>
            <li>
              <Link to={"/da/about-directorate"}>About</Link>
            </li>
            <li>
              <Link to={"/category/University-Admissions"}>Notifications</Link>
            </li>
            <li>
              <Link to={"/da/newsletter"}>Newsletter</Link>
            </li>
            <li>
              <Link to={"/da/gallery"}>Gallery</Link>
            </li>
            <li>
              <Link to={"/da/contact-us"}>Contact Us</Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};
export const DEMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className="department-menu">
      <div className="container">
        <div className="d-xl-none text-white align-items-center py-3 d-flex justify-content-between">
          Menu
          <span
            className="fad fa-list-ul"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </div>
        {internalMenuOpen && (
          <ul>
            <li>
              <Link to={"/de"}>Home</Link>
            </li>
            <li>
              <Link to={"/de/about-directorate"}>About</Link>
            </li>
            <li className="hasSubmenu">
              <Link to="#">Officers</Link>
              <ul>
                <li>
                  <Link to={"/profile/controller-of-examinations-pg"}>
                    Controller of Examinations
                  </Link>
                </li>
                <li>
                  <Link to={"/addl-controller-of-examinations"}>
                    Addl Controller of Examinations
                  </Link>
                </li>
              </ul>
            </li>
            <li className="hasSubmenu">
              <Link to="#">Downloads</Link>
              <ul>
                <li>
                  <Link to={"/category/University-Examinations"}>
                    DE Notifications
                  </Link>
                </li>
                <li>
                  <Link to={"/examination-branch-application-forms"}>
                    Application Forms
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <a
                href={"https://jntuaresults.ac.in/"}
                target="_blank"
                rel="noreferrer"
              >
                Results
              </a>
            </li>
            <li>
              <Link to={"/de/newsletter"}>Newsletter</Link>
            </li>
            <li>
              <Link to={"/de/gallery"}>Gallery</Link>
            </li>
            <li>
              <Link to={"/de/contact-us"}>Contact Us</Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export const DRDMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  const [DRD_PDF_Links, setDRDPdfLinks] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const data = await Get_DRD_PDF_Links();
      setDRDPdfLinks(data);
    };

    fetchData();
  }, []);
  return (
    <div className="department-menu">
      <div className="container">
        <div className="d-xl-none text-white align-items-center py-3 d-flex justify-content-between">
          Menu
          <span
            className="fad fa-list-ul"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </div>
        {internalMenuOpen && (
          <ul>
            <li>
              <Link to={"/drd"}>Home</Link>
            </li>
            <li>
              <Link to={"/drd/about-directorate"}>About</Link>
            </li>

            <li>
              <Link to={"/drd/ph-d-scholars-admitted"}>
                Ph.D. Scholars Admitted
              </Link>
            </li>
            <li className="hasSubmenu">
              <a href="javascript:void(0)">Syllabus</a>
              <ul>
                <li className="hasSubmenu">
                  <a href="javascript:void(0)">R21</a>
                  <ul>
                    <li>
                      <a
                        href={DRD_PDF_Links?.Research_and_Publication_Ethics}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Reseach & Publication Ethics
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="hasSubmenu">
                  <a href="javascript:void(0)">R18</a>
                  <ul>
                    <li>
                      <a
                        href={DRD_PDF_Links?.Research_Methodology}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Research Methodology
                      </a>
                    </li>
                    <li>
                      <a
                        href={DRD_PDF_Links?.IPR}
                        target="_blank"
                        rel="noreferrer"
                      >
                        IPR
                      </a>
                    </li>
                    <li>
                      <Link to={"/drd/part-1-part-2-subjects"}>
                        Part 1 and Part 2 subjects
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li className="hasSubmenu">
              <a href="javascript:void(0)">Research Centers</a>
              <ul>
                <li>
                  <a
                    href={DRD_PDF_Links?.Guidlines}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Guidlines
                  </a>
                </li>
                <li>
                  <a
                    href={DRD_PDF_Links?.Application_For_Research_Center}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Application for Research center
                  </a>
                </li>
                <li className="hasSubmenu">
                  <a href="javascript:void(0)">List of Research centers</a>
                  <ul>
                    <li className="hasSubmenu">
                      <a href="javascript:void(0)">Pharmacy</a>
                      <ul>
                        <li className="hasSubmenu">
                          <a href="javascript:void(0)">Engineering</a>
                          <ul>
                            <li>
                              <a
                                href={DRD_PDF_Links?.Constituent_Units}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Constituent Units
                              </a>
                            </li>
                            <li>
                              <a
                                href={DRD_PDF_Links?.Affilated_College}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Affilated College
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li className="ms-lg-auto hasSubmenu">
              <a href="javascript:void(0)">More</a>
              <ul>
                <li>
                  <Link to={"/category/Research-&-Development"}>
                    Notifications
                  </Link>
                </li>
                <li>
                  <Link to={"/drd/regulations"}>Regulations</Link>
                </li>
                <li>
                  <Link to={"/drd/downloads"}>Downloads</Link>
                </li>
                <li>
                  <Link to={"/drd/ph-d-vacancies"}>Ph.D. Vacancies</Link>
                </li>
                <li>
                  <Link to={"/drd/newsletter"}>Newsletter</Link>
                </li>
                <li>
                  <Link to={"/drd/gallery"}>Gallery</Link>
                </li>
                <li>
                  <Link to={"/drd/contact-us"}>Contact Us</Link>
                </li>
              </ul>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export const DIRPMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className="department-menu">
      <div className="container">
        <div className="d-xl-none text-white align-items-center py-3 d-flex justify-content-between">
          Menu
          <span
            className="fad fa-list-ul"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </div>
        {internalMenuOpen && (
          <ul>
            <li>
              <Link to={"/dirp"}>Home</Link>
            </li>
            <li>
              <Link to={"/dirp/about-directorate"}>About Directorate</Link>
            </li>
            <li>
              <Link to={"/profile/dirp-coordinator"}>Coordinator</Link>
            </li>
            <li>
              <Link to={"/category/Industrial-Relations-&-Placements"}>
                Notifications
              </Link>
            </li>
            <li>
              <Link to={"/dirp/placements"}>Placements</Link>
            </li>
            <li>
              <Link to={"/dirp/gallery"}>Gallery</Link>
            </li>
            <li>
              <Link to={"/dirp/contact-us"}>Contact Us</Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};
export const DICSMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className="department-menu">
      <div className="container">
        <div className="d-xl-none text-white align-items-center py-3 d-flex justify-content-between">
          Menu
          <span
            className="fad fa-list-ul"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </div>
        {internalMenuOpen && (
          <ul>
            <li>
              <Link to={"/dics"}>Home</Link>
            </li>
            <li>
              <Link to={"/dics/about-directorate"}>About Directorate</Link>
            </li>
            <li>
              <Link to={"/category/Industrial-Consultancy-Services"}>
                ICS Notifications
              </Link>
            </li>
            <li>
              <Link to={"/dics/gallery"}>Gallery</Link>
            </li>
            <li>
              <Link to={"/dics/contact-us"}>Contact Us</Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export const DFAAMMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  const [JNTUA_PDF_Links, setJNTUAPdfLinks] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const data = await Get_JNTUA_PDF_Links();
      setJNTUAPdfLinks(data);
    };

    fetchData();
  }, []);
  return (
    <div className="department-menu">
      <div className="container">
        <div className="d-xl-none text-white align-items-center py-3 d-flex justify-content-between">
          Menu
          <span
            className="fad fa-list-ul"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </div>
        {internalMenuOpen && (
          <ul>
            <li>
              <Link to={"/dfaam"}>Home</Link>
            </li>
            <li>
              <Link to={"/dfaam/about-directorate"}>About</Link>
            </li>
            <li className="hasSubmenu">
              <Link to="#">Foreign Affairs</Link>
              <ul>
                <li>
                  <Link to={"/dfaam/mous"}>MOU's/Collaborations</Link>
                </li>
                <li>
                  <Link to={"/dfaam/list-of-foreign-students"}>
                    List of Foreign Students
                  </Link>
                </li>
              </ul>
            </li>
            <li className="hasSubmenu">
              <Link to="#">Alumini Matters</Link>
              <ul>
                <li>
                  <a
                    href={JNTUA_PDF_Links?.Distiguished_Alumni}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Distiguished Alumni
                  </a>
                </li>
                <li>
                  <a
                    href={"https://alumni.jntua.ac.in/register.php"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Registration
                  </a>
                </li>
                <li>
                  <Link to={"/dfaam/sponsored-gold-medals"}>
                    Sponsored Gold Medals/Scholarship/Donations
                  </Link>
                </li>
                <li>
                  <Link to={"/dfaam/emratus"}>Emratus</Link>
                </li>
              </ul>
            </li>
            <li className="hasSubmenu">
              <Link to="#">UGC Matters</Link>
              <ul>
                <li>
                  <Link to={"/dfaam/12b-2f-certificates"}>
                    12(B) & 2(F) Certificates
                  </Link>
                </li>
                <li>
                  <Link to={"/dfaam/ugc-xi-plan"}>UGC XI Plan</Link>
                </li>
                <li>
                  <Link to={"/dfaam/ugc-xii-plan"}>UGC XII Plan Grants</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to={"/dfaam/notifications"}>Notifications</Link>
            </li>

            <li className="ms-lg-auto hasSubmenu">
              <a href="javascript:void(0)">More</a>
              <ul>
                <li>
                  <a
                    href={JNTUA_PDF_Links?.BTH_Students_List}
                    target="_blank"
                    rel="noreferrer"
                  >
                    BTH Students List
                  </a>
                </li>
                <li>
                  <Link to={"/dfaam/newsletter"}>Newsletter</Link>
                </li>
                <li>
                  <Link to={"/dfaam/gallery"}>Gallery</Link>
                </li>
                <li>
                  <Link to={"/dfaam/contact-us"}>Contact Us</Link>
                </li>
              </ul>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export const DFDMMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className="department-menu">
      <div className="container">
        <div className="d-xl-none text-white align-items-center py-3 d-flex justify-content-between">
          Menu
          <span
            className="fad fa-list-ul"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </div>
        {internalMenuOpen && (
          <ul>
            <li>
              <Link to={"/dfd"}>Home</Link>
            </li>
            <li>
              <Link to={"/dfd/committee"}>Committee</Link>
            </li>
            <li>
              <Link to={"/dfd/sss-minutes"}>SSS/Minutes</Link>
            </li>
            <li>
              <Link to={"/dfd/feedback"}>Feedback</Link>
            </li>
            <li>
              <Link to={"#"}>AQAR</Link>
            </li>
            <li>
              <Link to={"#"}>SSR</Link>
            </li>

            <li className="ms-lg-auto hasSubmenu">
              <Link to="#">More</Link>
              <ul>
                <li>
                  <Link to={"#"}>Circulars</Link>
                </li>
                <li>
                  <Link to={"/dfd/activities"}>Activities</Link>
                </li>
                <li>
                  <Link to={"#"}>Latest Information</Link>
                </li>
                <li>
                  <Link to={"/dfd/certificates"}>Certificates</Link>
                </li>
                <li>
                  <Link to={"/dfd/guidelines"}>Guidelines</Link>
                </li>
                <li>
                  <Link to={"/dfd/gallery"}>Gallery</Link>
                </li>
                <li>
                  <Link to={"/dfd/contact-us"}>Contact Us</Link>
                </li>
              </ul>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export const DSDCMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className="department-menu">
      <div className="container">
        <div className="d-xl-none text-white align-items-center py-3 d-flex justify-content-between">
          Menu
          <span
            className="fad fa-list-ul"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </div>
        {internalMenuOpen && (
          <ul>
            <li>
              <Link to={"/sdc"}>Home</Link>
            </li>
            <li>
              <Link to={"/sdc/about-directorate"}>About</Link>
            </li>
            <li>
              <Link to={"/sdc/people"}>People</Link>
            </li>
            <li>
              <Link to={"/sdc/newsletter"}>Newsletter</Link>
            </li>

            <li>
              <Link to={"/sdc/gallery"}>Gallery</Link>
            </li>
            <li>
              <Link to={"/sdc/contact-us"}>Contact Us</Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export const DWECMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className="department-menu">
      <div className="container">
        <div className="d-xl-none text-white align-items-center py-3 d-flex justify-content-between">
          Menu
          <span
            className="fad fa-list-ul"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </div>
        {internalMenuOpen && (
          <ul>
            <li>
              <Link to={"/wec"}>Home</Link>
            </li>
            <li>
              <Link to={"/wec/about-directorate"}>About</Link>
            </li>
            <li>
              <Link to={"/wec/internal-compliant-committee"}>
                Internal Compliant Committee
              </Link>
            </li>
            <li>
              <Link to={"/wec/notifications"}>Notifications</Link>
            </li>

            <li className="ms-lg-auto hasSubmenu">
              <a href="javascript:void(0)">More</a>
              <ul>
                <li>
                  <Link to={"/wec/newsletter"}>Newsletter</Link>
                </li>
                <li>
                  <Link to={"/wec/programmes"}>Programmes</Link>
                </li>
                <li>
                  <Link to={"/wec/gallery"}>Gallery</Link>
                </li>
                <li>
                  <Link to={"/wec/contact-us"}>Contact Us</Link>
                </li>
              </ul>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export const SDICMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className="department-menu">
      <div className="container">
        <div className="d-xl-none text-white align-items-center py-3 d-flex justify-content-between">
          Menu
          <span
            className="fad fa-list-ul"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </div>
        {internalMenuOpen && (
          <ul>
            <li>
              <Link to={"/sdic"}>Home</Link>
            </li>
            <li>
              <Link to={"/sdic/about-us"}>About Us</Link>
            </li>
            <li>
              <Link to={"/sdic/downloads"}>Downloads</Link>
            </li>
            <li>
              <Link to={"/sdic/others"}>Others</Link>
            </li>
            <li>
              <Link to={"/sdic/gallery"}>Gallery</Link>
            </li>
            <li>
              <Link to={"/sdic/contact-us"}>Contact Us</Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export const NSSMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className="department-menu">
      <div className="container">
        <div className="d-xl-none text-white align-items-center py-3 d-flex justify-content-between">
          Menu
          <span
            className="fad fa-list-ul"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </div>
        {internalMenuOpen && (
          <ul>
            <li>
              <Link to={"/nss"}>Home</Link>
            </li>
            <li>
              <Link to={"/nss/about-us"}>About Us</Link>
            </li>
            <li>
              <Link to={"/nss/downloads"}>Downloads</Link>
            </li>
            <li>
              <Link to={"/nss/others"}>Others</Link>
            </li>
            <li>
              <Link to={"/nss/gallery"}>Gallery</Link>
            </li>
            <li>
              <Link to={"/nss/contact-us"}>Contact Us</Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export const SCSTMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className="department-menu">
      <div className="container">
        <div className="d-xl-none text-white align-items-center py-3 d-flex justify-content-between">
          Menu
          <span
            className="fad fa-list-ul"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </div>
        {internalMenuOpen && (
          <ul>
            <li>
              <Link to={"/scst"}>Home</Link>
            </li>
            <li>
              <Link to={"/scst/about-us"}>About Us</Link>
            </li>
            <li>
              <Link to={"/scst/downloads"}>Downloads</Link>
            </li>
            <li>
              <Link to={"/scst/others"}>Others</Link>
            </li>
            <li>
              <Link to={"/scst/gallery"}>Gallery</Link>
            </li>
            <li>
              <Link to={"/scst/contact-us"}>Contact Us</Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export const SPORTSMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className="department-menu">
      <div className="container">
        <div className="d-xl-none text-white align-items-center py-3 d-flex justify-content-between">
          Menu
          <span
            className="fad fa-list-ul"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </div>
        {internalMenuOpen && (
          <ul>
            <li>
              <Link to={"/sports"}>Home</Link>
            </li>
            <li>
              <Link to={"/sports/about-us"}>About Us</Link>
            </li>
            <li>
              <Link to={"/sports/downloads"}>Downloads</Link>
            </li>
            <li>
              <Link to={"/sports/others"}>Others</Link>
            </li>
            <li>
              <Link to={"/sports/gallery"}>Gallery</Link>
            </li>
            <li>
              <Link to={"/sports/contact-us"}>Contact Us</Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export const ARCMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className="department-menu">
      <div className="container">
        <div className="d-xl-none text-white align-items-center py-3 d-flex justify-content-between">
          Menu
          <span
            className="fad fa-list-ul"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </div>
        {internalMenuOpen && (
          <ul>
            <li>
              <Link to={"/arc"}>Home</Link>
            </li>
            <li>
              <Link to={"/arc/about-us"}>About Us</Link>
            </li>
            <li>
              <Link to={"/arc/downloads"}>Downloads</Link>
            </li>
            <li>
              <Link to={"/arc/others"}>Others</Link>
            </li>
            <li>
              <Link to={"/arc/gallery"}>Gallery</Link>
            </li>
            <li>
              <Link to={"/arc/contact-us"}>Contact Us</Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export const SRMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  const [SR_PDF_Links, setJNTUAPdfLinks] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const data = await Get_SR_PDF_Links();
      setJNTUAPdfLinks(data);
    };

    fetchData();
  }, []);
  return (
    <div className="department-menu">
      <div className="container">
        <div className="d-xl-none text-white align-items-center py-3 d-flex justify-content-between">
          Menu
          <span
            className="fad fa-list-ul"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </div>
        {internalMenuOpen && (
          <ul>
            <li>
              <Link to={"/sr"}>Home</Link>
            </li>
            <li>
              <a
                href={SR_PDF_Links?.About_Directorate}
                target="_blank"
                rel="noreferrer"
              >
                About
              </a>
            </li>
            <li>
              <a
                href={SR_PDF_Links?.Newsletter}
                target="_blank"
                rel="noreferrer"
              >
                Newsletter
              </a>
            </li>
            <li>
              <a
                href={SR_PDF_Links?.Patents_Published}
                target="_blank"
                rel="noreferrer"
              >
                Patents Published/Granted
              </a>
            </li>
            <li>
              <Link to={"/sr/research-seed-grant"}>Research Seed Grant</Link>
            </li>
            <li>
              <a
                href={SR_PDF_Links?.Research_Projects}
                target="_blank"
                rel="noreferrer"
              >
                Research Projects
              </a>
            </li>
            <li>
              <a
                href={SR_PDF_Links?.Research_Facilities}
                target="_blank"
                rel="noreferrer"
              >
                Research Facilities
              </a>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};
