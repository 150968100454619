import React, { Component } from "react";
import SliderDefault from "../../components/Slider/SliderDefault";
import AboutText from "./AboutTextSection";
import Events from "./EventsSection";
import ConstituentUnits from "./ConstituentUnits";
import Directorates from "./Directorates";
import Happenings from "./HappeningsSection";
import Accreditation from "../../components/Common/Accreditation";
import ScrollToTop from "../../components/Common/ScrollTop";

class HomeMain extends Component {
  render() {
    return (
      <React.Fragment>
        {/* SliderDefault-start */}
        <SliderDefault />
        {/* SliderDefault-start */}

        {/* AboutText Start */}
        <AboutText />
        {/* AboutText End */}
        <ConstituentUnits />
        <Events />
        <Directorates />
        <Happenings />
        <Accreditation />

        {/* scrolltop-start */}
        <ScrollToTop scrollClassName="scrollup orange-color" />
        {/* scrolltop-end */}
      </React.Fragment>
    );
  }
}

export default HomeMain;
