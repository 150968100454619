import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import ScrollToTop from "../../components/Common/ScrollTop";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SearchModal from "../../components/Layout/Header/SearchModal";
import footerLogo from "../../assets/img/logo/jntua-logo.png";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData, imageBaseURL } from "../../utils/fetchData";
import Accreditation from "../../components/Common/Accreditation";
import { AccreditationMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import RightSidebar from "../../components/Common/RightSidebar";
import pdf from "../../assets/img/pdf.png";
import { useFontSize } from "../../FontSizeContext";

const Post_Graduate_Courses = () => {
  const [data, setData] = useState([]);
  const { fontSize } = useFontSize();
  useEffect(() => {
    fetchData(
      JNTUK_API_Urls.Post_Graduate_Syllabus + `&pagination[pageSize]=1000`,
      setData
    );
  }, []);
  const getList = (course) => {
    const list = data?.data?.filter(
      (obj) => obj?.attributes?.Course === course
    );
    return (
      <div className="overflow-x">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Title</th>
              <th scope="col">Download</th>
            </tr>
          </thead>
          <tbody>
            {list?.map((obj, index) => (
              <tr>
                <td>{(index + 1).toString().padStart(2, "0")}.</td>
                <td>{obj?.attributes?.Title}</td>
                <td align="center">
                  {obj?.attributes?.File?.data?.attributes?.url ? (
                    <a
                      href={
                        imageBaseURL +
                        obj?.attributes?.File?.data?.attributes?.url
                      }
                      className="d-flex flex-column align-items-center"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt="PDF file"
                        title="PDF file"
                        src={pdf}
                        className="mb-5"
                        style={{
                          width: "30px",
                          height: "30px",
                          marginLeft: "10px",
                        }}
                      />
                    </a>
                  ) : (
                    <img
                      alt="PDF file"
                      title="PDF file"
                      src={pdf}
                      className="mb-5"
                      style={{
                        width: "30px",
                        height: "30px",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>Post Graduate Courses | JNTU Anantapur</title>
        <meta
          name="description"
          content="Post Graduate Courses of Jawaharlal Nehru Technological University Anantapur"
        />
        <meta
          name="keywords"
          content="Post Graduate Courses of Jawaharlal Nehru Technological University Anantapur"
        ></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="home"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />
      <style>
        {`
                    body,
                    .rs-latest-events.style1 .event-wrap .events-short .date-part .date,
                    .widget ul li a {
                        font-size: ${fontSize}px;
                    }
                    .rs-inner-blog .blog-item .blog-content .blog-title,
                    .widget h1,.content-wrapper h2, .heading, .college-title{
                        font-size: calc(${fontSize}px + 10px);
                    }
                    .breadcrumb-bg .breadcrumbs-text ul li{
                      font-size: calc(${fontSize}px + 2px);
                    }
                    
                    .full-width-header .rs-header .topbar-area .topbar-right li.login-register a,
                    .readon.orange-btn, .rs-latest-events.bg-wrap .notification-filter button,
                    .rs-latest-events.style1 .event-wrap:not(.ls-area) .events-short .slNo, 
                    .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > a,
                    .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > ul li > a,
                    table th, table td {                       
                        font-size: calc(${fontSize}px - 2px);
                    }

                    .rs-footer .footer-top-social .social_msg{
                        font-size: calc(${fontSize}px + 18px);
                    }
                    .rs-footer .footer-center h3{
                        font-size: calc(${fontSize}px + 12px);
                    }
                    .rs-footer.home9-style .footer-top .widget-title,
                    .rs-team.style1 .about-btn--primary,
                    .rs-team.style1 .about-btn--secondary,
                    .course-overview .inner-box h5{
                        font-size: calc(${fontSize}px + 4px);
                    }
                    .rs-footer .footer-center h4,
                    .rightBar h4{
                        font-size: calc(${fontSize}px + 6px);
                    }
                    
                `}
      </style>
      <div className="pageTopOffset">
        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
          pageCategory={{ label: "Syllabus", path: "syllabus" }}
          pageTitle="Post Graduate Courses"
          pageName="Post Graduate Courses"
          breadcrumbsClass="breadcrumb-bg"
        />
        {/* breadcrumb-area-start */}

        <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 xl-mb-30">
                <div className="about-intro">
                  <AccreditationMenu />
                  <SideBar />
                </div>
              </div>
              <div className="col-xl-6 pl-20 xl-pl-14">
                <div className="row">
                  <div className="rs-inner-blog col-lg-12">
                    <h3 className="college-title">Post Graduate Courses</h3>
                    <h4>Civil Engineering</h4>
                    {getList("Civil Engineering")}
                    <h4>Computer Science Engineering</h4>
                    {getList("Computer Science Engineering")}
                    <h4>Electronics & Communication Engineering</h4>
                    {getList("Electronics & Communication Engineering")}
                    <h4>Electrical & Electronics Engineering</h4>
                    {getList("Electrical & Electronics Engineering")}
                    <h4>M.Pharmacy</h4>
                    {getList("M.Pharmacy")}
                    <h4>MBA</h4>
                    {getList("MBA")}
                    <h4>Mechanical Engineering</h4>
                    {getList("Mechanical Engineering")}
                    <h4>MCA</h4>
                    {getList("MCA")}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 pl-40 xl-pl-14">
                <RightSidebar />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Accreditation />
      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <ScrollToTop scrollClassName="scrollup orange-color" />
      <SearchModal />
    </React.Fragment>
  );
};

export default Post_Graduate_Courses;
