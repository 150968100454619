/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useFontSize } from "../../../FontSizeContext";

const TopHeader = (props) => {
  const location = useLocation();
  const { fontSize, increaseFontSize, decreaseFontSize, resetFontSize } =
    useFontSize();
  const { topBarClass } = props;

  const date = () => {
    return new Date().toLocaleString("en-IN", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  return (
    <div
      className={
        topBarClass ? topBarClass : "topbar-area home8-topbar hidden-md"
      }
    >
      <style>
        {`
              body,
              .rs-latest-events.style1 .event-wrap .events-short .date-part .date,
              .site-wrap h4 {
                  font-size: ${fontSize}px;
              }
              .sec-title .sub-title,
              .rs-about.style1 .info-part h2{
                  font-size: calc(${fontSize}px + 16px);
              }
              .rs-slider.main-home .fixedBannerTitle .content-part .sl-title{
                  font-size: calc(${fontSize}px + 56px);
              }
              .rs-slider.main-home .fixedBannerTitle .content-part .sl-sub-title{
                  font-size: calc(${fontSize}px + 26px);
              }
              .full-width-header .rs-header .topbar-area .topbar-right li.login-register a,
              .readon.orange-btn, .rs-latest-events.bg-wrap .notification-filter button,
              .rs-latest-events.style1 .event-wrap:not(.ls-area) .events-short .slNo, 
              .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > a,
              .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > ul li > a,
              .rs-latest-events.style1 .event-wrap:not(.ls-area) .events-short .date-part .date,
              .rs-latest-events.bg-wrap .index-container .viewall-notification,
              .rs-latest-events.bg-wrap .index-container .index-item {                       
                  font-size: calc(${fontSize}px - 2px);
              }
              .rs-footer .footer-top-social .social_msg{
                  font-size: calc(${fontSize}px + 18px);
              }
              .rs-footer .footer-center h3{
                  font-size: calc(${fontSize}px + 12px);
              }
              .rs-footer.home9-style .footer-top .widget-title{
                  font-size: calc(${fontSize}px + 4px);
              }
              .rs-footer .footer-center h4, .rs-about.style1 .info-part h4{
                  font-size: calc(${fontSize}px + 6px);
              }
              @media only screen and (max-width: 767px) {
                  .sec-title .sub-title {
                      font-size: calc(${fontSize}px + 2px);
                  }
              }
              @media (max-width: 575px){
                  .rs-about.style1 .info-part h2 {
                      font-size: calc(${fontSize}px + 8px);
                  }
              }
              @media only screen and (max-width: 480px) {
                  .rs-slider.main-home .slider-content .content-part .sl-sub-title {
                      font-size: calc(${fontSize}px + 2px);
                  }
              
                  .rs-slider.main-home .slider-content .content-part .sl-title {
                      font-size: calc(${fontSize}px + 16px) ;
                  }
              }
           .full-width-header .rs-header .topbar-area .topbar-contact li div.naac-accreditation > strong {
              font-size: calc(${fontSize}px - 4px);
            }
            .full-width-header .rs-header .topbar-area .topbar-contact li div.naac-accreditation .naac-accreditation-content p{
              font-size: calc(${fontSize}px - 2px);
            }

            .content-wrapper h2{
              font-size: calc(${fontSize}px + 16px);
            }
            .pageTopOffset, .rs-slider.main-home {
              padding-top: calc(142px + ${fontSize}px);
          
              @media #{$lg} {
                  padding-top: 66px;
              }
          }
        `}
      </style>
      <div className="container-fluid">
        <div className="row y-middle">
          <div className="col-md-2">
            <ul className="topbar-contact">
              <li>
                <i class="fa-regular fa-calendar-days me-2"></i>
                <span>{date()}</span>
              </li>

            </ul>
          </div>
          <div className="col nav-bar-top">
            <ul className="topbar-right">
              <li className={location.pathname === "/category/Latest-News" ? 'login-register active' : 'login-register'}>
                <Link to="/category/Latest-News">
                  NOTIFICATION CENTER
                </Link>
              </li>
              <li className="login-register">
                <Link to="#">SPOTLIGHT</Link>
              </li>
              <li className="login-register">
                <Link to="/profile/director-of-foreign-affairs-alumni-matters">ALUMNI</Link>
              </li>
              <li className="login-register">
                <Link to="#">STUDENT CORNER</Link>
              </li>
              <li className={location.pathname === "/gallery/academic-events" ? 'login-register active' : 'login-register'}>
                <Link to="/gallery/academic-events">NEWS & EVENTS</Link>
              </li>
              <li className="login-register">
                <Link to="/our-faculty">FACULTY CORNER</Link>
              </li>
              <li className="login-register">
                <a
                  href="https://mail.google.com/a/jntua.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  JNTUA EMAIL
                </a>
              </li>
            </ul>
            <button onClick={decreaseFontSize}>A-</button>
            <button onClick={resetFontSize}>A</button>
            <button onClick={increaseFontSize}>A+</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
