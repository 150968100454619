import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MenuItems from "./MenuItems";
import RSMobileMenu from "./RSMobileMenu";
import TopHeader from "./TopBar";

import normalLogo from "../../../assets/img/logo/jntua-logo.png";
import darkLogo from "../../../assets/img/logo/Jntuk-logo.png";
import naacLogo from "../../../assets/img/logo/naac-logo.png";
import svnYrs from "../../../assets/img/logo/75yrs.png";
import akamLogo from "../../../assets/img/logo/Azadi-Ka-Amrit-Mahotsav-Logo.png";
import { Get_JNTUA_PDF_Links } from "../../../utils/fetchData";

const Header = (props) => {
  const {
    headerClass,
    parentMenu,
    secondParentMenu,
    activeMenu,
    headerNormalLogo,
    headerStickyLogo,
    mobileNormalLogo,
    TopBar,
    TopBarClass,
    emailAddress,
    phoneNumber,
    Location,
  } = props;
  const [menuOpen, setMenuOpen] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const [JNTUA_PDF_Links, setJNTUAPdfLinks] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const data = await Get_JNTUA_PDF_Links();
      setJNTUAPdfLinks(data);
    };
  
    fetchData();
  }, []);
  
  useEffect(() => {
    // Sticky is displayed after scrolling for 100 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const searchModalAdd = () => {
    const body = document.body;
    body.classList.toggle("modal-open");
    body.classList.toggle("overflow-hidden");
  };


  return (
    <React.Fragment>
      <div
        className={
          headerClass ? headerClass : "full-width-header home8-style4 main-home"
        }
      >
        <header id="rs-header" className="rs-header">
          {TopBar ? (
            <TopHeader
              topBarClass={TopBarClass}
              emailAddress={emailAddress}
              phoneNumber={phoneNumber}
              Location={Location}
            />
          ) : (
            ""
          )}

          <div
            className={
              isVisible ? "menu-area menu-sticky" : "menu-area menu-sticky"
            }
          >
            <div className="container-fluid">
              <div className="row y-middle">
                <div className="col-xl-5 hidden-lg">
                  <div className="logo-area ">
                    <Link to="/">
                      <img
                        className="normal-logo"
                        src={headerNormalLogo ? headerNormalLogo : normalLogo}
                        title="Jawaharlal Nehru Technological University Anantapur Logo"
                        alt="Jawaharlal Nehru Technological University Anantapur Logo"
                      />
                      <img
                        className="sticky-logo"
                        src={headerStickyLogo ? headerStickyLogo : darkLogo}
                        title="Jawaharlal Nehru Technological University Anantapur Logo"
                        alt="Jawaharlal Nehru Technological University Anantapur Logo"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-xl-7 text-end">
                  <div className="rs-menu-area">
                    <div className="main-menu">
                      <div className="mobile-menu lg-display-block">
                        <Link to="/" className="mobile-normal-logo">
                          <img
                            className="normal-logo"
                            src={
                              mobileNormalLogo ? mobileNormalLogo : normalLogo
                            }
                            title="Jawaharlal Nehru Technological University Anantapur Logo"
                            alt="Jawaharlal Nehru Technological University Anantapur Logo"
                          />
                        </Link>
                        <Link to="/" className="mobile-sticky-logo">
                          <img
                            src={mobileNormalLogo ? mobileNormalLogo : darkLogo}
                            title="Jawaharlal Nehru Technological University Anantapur Logo"
                            alt="Jawaharlal Nehru Technological University Anantapur Logo"
                          />
                        </Link>
                        <span
                          onClick={searchModalAdd}
                          className="rs-search bold-text mr-20"
                        >
                          <i className="far fa-search"></i>
                        </span>
                        <span
                          className="rs-menu-toggle"
                          onClick={() => {
                            setMenuOpen(!menuOpen);
                          }}
                        >
                          <i className="fa fa-bars"></i>
                        </span>
                      </div>
                      <div className="row hidden-lg">
                        <div className="col-md-4">
                          <img
                            className=""
                            src={naacLogo}
                            title="NAAC Logo"
                            alt="NAAC Logo"
                          />
                        </div>
                        <div className="col-md-4">
                          <img
                            className=""
                            src={svnYrs}
                            title="75 years Logo"
                            alt="75 years Logo"
                          />
                        </div>
                        <div className="col-md-4">
                          <img
                            className=""
                            src={akamLogo}
                            title="Azadi Ka Amrit Mahotsav Logo"
                            alt="Azadi Ka Amrit Mahotsav Logo"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt-1">
              <div className="rs-menu-area justify-content-between">
                <div className="main-menu w-100 hidden-lg">
                  <nav className="rs-menu">
                    <ul className="nav-menu d-flex">
                      <MenuItems
                        parentMenu={parentMenu}
                        secondParentMenu={secondParentMenu}
                        activeMenu={activeMenu}
                      />
                      <li className="rs-mega-menu menu-item-has-children additional-menus submenuRightAligned ms-auto">
                        <Link to="#">FOR MORE...</Link>
                        <ul className="mega-menu">
                          <li className="mega-menu-container">
                            <div className="single-megamenu">
                              <ul className="sub-menu">
                                <li>
                                  <Link to="/registered-ph-d-scholars">
                                    Registered Ph.D. Scholars
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/category/Events">Events</Link>
                                </li>
                                <li>
                                  <Link to="/archive">Archive</Link>
                                </li>
                                <li>
                                  <a
                                    href={JNTUA_PDF_Links.Publishers_Pdf_File}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    List of suggested books Indian Author
                                    Publishers
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href={
                                      "https://cets.apsche.ap.gov.in/APSCHE/APSCHEHome.aspx"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    AP EAPCET 2022
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href={JNTUA_PDF_Links.MoRTH_Recruitment}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    MoRTH Recruitment
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href={
                                      "https://cets.apsche.ap.gov.in/APPGCET2023/UI/HomePages/Home"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    AP PGCET 2023
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="single-megamenu">
                              <ul className="sub-menu">
                                <li>
                                  <Link to="#">Download</Link>
                                  <ul>
                                    <li>
                                      <a
                                        href={
                                          JNTUA_PDF_Links.Procedure_for_Organizing_Graduation_day
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        Procedure for Organizing Graduation Day
                                      </a>
                                    </li>
                                    <li>
                                      <Link to="/guidelines-for-donation">
                                        Guidelines for Donation
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/research-development">
                                        Research & Development
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/syllabus">Syllabus</Link>
                                    </li>
                                    <li>
                                      <Link to="/annual-reports">
                                        Annual Reports
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/examination-branch-application-forms">
                                        Examination Branch Application Forms
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/news-letters">
                                        News Letters
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                            <div className="single-megamenu">
                              <ul className="sub-menu">
                                <li>
                                  <Link to="#">Important Units</Link>
                                  <ul>
                                    <li>
                                      <Link to="/profile/director-of-skill-development-centre-incubation-centre">
                                        Skill Development & Incubation Center
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/nss">NSS Cell</Link>
                                    </li>
                                    <li>
                                      <Link to="/scst">SC/ST Cell</Link>
                                    </li>
                                    <li>
                                      <Link to="/sports">Sports Council</Link>
                                    </li>
                                    <li>
                                      <Link to="/arc">Anti-Ragging Cell</Link>
                                    </li>
                                    <li>
                                      <Link to="/profile/public-relations-office">
                                        Public Relations Office
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className="sidebarmenu-search">
                        <span
                          onClick={searchModalAdd}
                          className="rs-search"
                          aria-label="Search"
                        >
                          <i className="flaticon-search"></i>
                        </span>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <RSMobileMenu
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            parentMenu={parentMenu}
            secondParentMenu={secondParentMenu}
          />
          <div
            onClick={() => setMenuOpen(false)}
            className={menuOpen ? "body-overlay show" : "body-overlay"}
          ></div>
        </header>
      </div>
    </React.Fragment>
  );
};

export default Header;
