import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../../../components/Layout/Header/Header";
import Footer from "../../../../components/Layout/Footer/Footer";
import ScrollToTop from "../../../../components/Common/ScrollTop";
import OffWrap from "../../../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../../../components/Common/Breadcumb";
import SearchModal from "../../../../components/Layout/Header/SearchModal";
import GalleryPart from "./GalleryPart";
import footerLogo from "../../../../assets/img/logo/jntua-logo.png";
import Accreditation from "../../../../components/Common/Accreditation";
import { getMenu } from "../../../../utils/getDomainData";

const CommonGalleryALL = () => {
  const { state } = useLocation();
  let location = useLocation();
  const currentDomain = location.pathname.split("/")[1];
  return (
    <React.Fragment>
      <Helmet>
        <title>{state?.type} | JNTU Anantapur</title>
        <meta
          name="description"
          content="Gallery Jawaharlal Nehru Technological University Anantapur"
        />
        <meta
          name="keywords"
          content="Gallery Jawaharlal Nehru Technological University Anantapur"
        ></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="home"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />

      <div className="pageTopOffset">
        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
          pageTitle={state?.type}
          pageName={state?.type}
          pageCategory={{ label: "Gallery", path: `${currentDomain}/gallery` }}
          breadcrumbsClass="breadcrumb-bg"
        />
        {/* breadcrumb-area-start */}
        {getMenu(currentDomain)}
        <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
          <div className="container">
            <div className="row">
              <GalleryPart title={state.title || ""} images={state.images} />
            </div>
          </div>
        </div>
      </div>
      <Accreditation />
      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <ScrollToTop scrollClassName="scrollup orange-color" />
      <SearchModal />
    </React.Fragment>
  );
};

export default CommonGalleryALL;
