import React, { useState, useEffect } from "react";
import { fetchData } from "../../../utils/fetchData";
import { JNTUK_API_Urls } from "../../../utils/api.config";
import { Link, useHistory } from "react-router-dom";
function convertToPath(inputString) {
  // Replace spaces with dashes and remove special characters
  const path = inputString
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "-")
    .replace(/-+/g, "-")
    .replace(/^-|-$/g, "");

  // Encode the resulting string
  const encodedPath = encodeURIComponent(path);

  return encodedPath;
}

const SearchModal = () => {
  const [data, setData] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [liveScrolling, setLiveScrolling] = useState([]);
  const [searchField, setSearchField] = useState("");
  const history = useHistory();
  useEffect(() => {
    fetchData(
      JNTUK_API_Urls["Site-Map"] + `?filters[Title][$containsi]=${searchField}`,
      setData
    );
    fetchData(
      `/notifications?filters[Title][$containsi]=${searchField}`,
      setNotifications
    );
    fetchData(
      `${JNTUK_API_Urls.Live_Scrolling}&filters[Title][$containsi]=${searchField}`,
      setLiveScrolling
    );
  }, [searchField]);

  useEffect(() => {
    // Listen for navigation events
    const unlisten = history.listen(() => {
      searchModalRemove();
    });

    // Cleanup the listener on unmount
    return () => {
      unlisten();
    };
  }, [history]);

  const searchModalRemove = () => {
    document.body.classList.remove("modal-open", "overflow-hidden");
  };

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="search-modal">
        <div
          className="h-100 w-100 position-fixed"
          onClick={searchModalRemove}
        ></div>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="search-block clearfix">
              <form>
                <div className="form-group mb-0">
                  <input
                    className="form-control"
                    placeholder="Search Here..."
                    type="text"
                    onChange={handleChange}
                  />
                </div>
              </form>
              <div className="search-results-lists">
                <ul>
                  {searchField &&
                    [
                      ...data?.data,
                      ...liveScrolling?.data,
                      ...notifications?.data,
                    ]?.map((obj) => (
                      <li key={obj?.attributes.Title} className="list-item">
                        {obj?.attributes?.Path?.includes("http") ? (
                          <a
                            href={obj?.attributes?.Path}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="result-title">
                              {obj?.attributes?.Title}
                            </div>
                            <div className="result-description">
                              {obj?.attributes?.Description || ""}
                            </div>
                          </a>
                        ) : (
                          <Link
                            to={{
                              pathname:
                                obj?.attributes?.Path ||
                                `/notification/${convertToPath(
                                  obj?.attributes?.Title
                                )}`,
                              state: { notification: obj },
                            }}
                            onClick={() => searchModalRemove()}
                          >
                            <div className="result-title">
                              {obj?.attributes?.Title}
                            </div>
                            <div className="result-description">
                              {obj?.attributes?.Description || ""}
                            </div>
                          </Link>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SearchModal;
