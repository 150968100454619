import React, { useEffect, useState } from "react";
import { AcademicsMenu} from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData } from "../../utils/fetchData";
import AboutPart from "./AboutPart";
import ScrollToTop from "../../components/Common/ScrollTop";
import RightSidebar from "../../components/Common/RightSidebar";

const Content = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchData(JNTUK_API_Urls.Controller_of_Examination_UG, setData);
  }, []);

  return (
    <React.Fragment>
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 xl-mb-30">
              <div className="about-intro">
                <AcademicsMenu />
                <SideBar />
              </div>
            </div>
            <div className="col-xl-6 pl-20 xl-pl-14">
              <div className="content-wrapper intro-section">
                <h2 title={data?.data?.attributes?.Page_Heading}>{data?.data?.attributes?.Page_Heading}</h2>

                <AboutPart data={data} />
              </div>
            </div>
            <div className="col-xl-3 pl-40 xl-pl-14">
                  <RightSidebar />
                </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default Content;
