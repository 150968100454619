import React from 'react';
import { Link } from 'react-router-dom';

const FooterBottom = () => {
    return (
        <div className="footer-bottom">
            <div className="container">
                <div className="row y-middle">
                    <div className="col-lg-12 md-mb-10">
                        <div className="copyright md-mb-0">
                            <p>All Copyright © 2024 Jawaharlal Nehru Technological University, Anantapur. All Rights Reserved.</p>
                        </div>
                    </div>
                    <div className="col-lg-12 text-center md-text-left">
                        <ul className="copy-right-menu">
                            <li><Link to="/website-policy">Website Policies</Link></li>
                            <li><Link to="/disclaimer">Disclaimer</Link></li>
                            <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterBottom;