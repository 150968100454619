import React  from "react";
import { AboutMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";

import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import RightSidebar from "../../components/Common/RightSidebar";


const Content = () => {

  return (
    <React.Fragment>
      <SiteBreadcrumb
        pageName="Location Map"
      />
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 xl-mb-30">
              <div className="about-intro">
                <AboutMenu />
                <SideBar />
              </div>
            </div>

            <div className="col-xl-6 pl-20 xl-pl-14">
              <div className="content-wrapper">
                <div className="content-section">
                <h2 title="Location Map">Location Map</h2>
                <iframe title="Location Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3860.077813705945!2d77.60589311461557!3d14.651524189770047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb14a555e1d626f%3A0x92d44831356941d1!2sJawaharlal+Nehru+Technological+University+Anantapur!5e0!3m2!1sen!2sus!4v1535200108942"
                width="100%"
                height="600"
              ></iframe>
                </div>
              </div>
            </div>
            <div className="col-xl-3 pl-40 xl-pl-14">
                  <RightSidebar />
                </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default Content;
