import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../../../components/Layout/Header/Header";
import Footer from "../../../../components/Layout/Footer/Footer";
import ScrollToTop from "../../../../components/Common/ScrollTop";
import OffWrap from "../../../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../../../components/Common/Breadcumb";
import SearchModal from "../../../../components/Layout/Header/SearchModal";
import GalleryPart from "./GalleryPart";
import footerLogo from "../../../../assets/img/logo/jntua-logo.png";
import Accreditation from "../../../../components/Common/Accreditation";
import { useLocation } from "react-router-dom";
import { fetchData } from "../../../../utils/fetchData";
import { useFontSize } from "../../../../FontSizeContext";
import { getAPIPath, getMenu } from "../../../../utils/getDomainData";

const CommonGallery = () => {
  const { fontSize } = useFontSize();
  const [data, setData] = useState([]);
  let location = useLocation();
  const currentDomain = location.pathname.split("/")[1];
  useEffect(() => {
    fetchData(getAPIPath(currentDomain)?.gallery, setData);
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>Gallery | JNTU Anantapur</title>
        <meta
          name="description"
          content="Gallery Jawaharlal Nehru Technological University Anantapur"
        />
        <meta
          name="keywords"
          content="Gallery Jawaharlal Nehru Technological University Anantapur"
        ></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="home"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />
      <style>
        {`
                    body,
                    .content-wrapper .content-section .events-short .date-part .date,
                    .widget ul li a {
                        font-size: ${fontSize}px;
                    }
                    .rs-inner-blog .blog-item .blog-content .blog-title,
                    .widget h1,.content-wrapper h2, .heading, .college-title,
                    .content-wrapper .content-section h3{
                        font-size: calc(${fontSize}px + 10px);
                    }
                    .breadcrumb-bg .breadcrumbs-text ul li,
                    .notficationTitle, h6{
                      font-size: calc(${fontSize}px + 2px);
                    }
                    
                    .full-width-header .rs-header .topbar-area .topbar-right li.login-register a,
                    .readon.orange-btn, .rs-latest-events.bg-wrap .notification-filter button,
                    .rs-latest-events.style1 .event-wrap:not(.ls-area) .events-short .slNo, 
                    .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > a,
                    .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > ul li > a {                       
                        font-size: calc(${fontSize}px - 2px);
                    }

                    .rs-footer .footer-top-social .social_msg{
                        font-size: calc(${fontSize}px + 18px);
                    }
                    .rs-footer .footer-center h3{
                        font-size: calc(${fontSize}px + 12px);
                    }
                    .rs-footer.home9-style .footer-top .widget-title,
                    .content-wrapper .content-section h4{
                        font-size: calc(${fontSize}px + 4px);
                    }
                    .rs-footer .footer-center h4{
                        font-size: calc(${fontSize}px + 6px);
                    }
                    
                `}
      </style>

      <div className="pageTopOffset">
        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
          pageTitle="Gallery"
          pageName="Gallery"
          breadcrumbsClass="breadcrumb-bg"
        />
        {/* breadcrumb-area-start */}
        {getMenu(currentDomain)}
        <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
          <div className="container">
            <div className="row">
              <h3 className="blog-title" title="Gallery">
                Gallery
              </h3>

              {data?.data?.map((obj) => (
                <GalleryPart
                  title={obj?.attributes?.Gallery_Title || ""}
                  images={obj?.attributes?.Images}
                  type="Gallery - View All"
                />
              ))}
              {data?.data?.length === 0 && <p>Gallery data not available.</p>}
            </div>
          </div>
        </div>
      </div>
      <Accreditation />
      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <ScrollToTop scrollClassName="scrollup orange-color" />
      <SearchModal />
    </React.Fragment>
  );
};

export default CommonGallery;
