import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../../../components/Layout/Header/Header";
import Footer from "../../../../components/Layout/Footer/Footer";
import ScrollToTop from "../../../../components/Common/ScrollTop";
import OffWrap from "../../../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../../../components/Common/Breadcumb";
import SearchModal from "../../../../components/Layout/Header/SearchModal";
import footerLogo from "../../../../assets/img/logo/jntua-logo.png";
import { fetchData } from "../../../../utils/fetchData";
import Accreditation from "../../../../components/Common/Accreditation";
import { useFontSize } from "../../../../FontSizeContext";
import { getMenu } from "../../../../utils/getDomainData";
import { JNTUK_API_Urls } from "../../../../utils/api.config";

const Dual_Degree_Programmes = () => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  let location = useLocation();
  const currentDomain = location.pathname.split("/")[1];
  const { fontSize } = useFontSize();
  useEffect(() => {
    fetchData(
      JNTUK_API_Urls.Dual_Degree_Programmes +
        `&filters[Category][$contains]=Integrated&pagination[pageSize]=1000`,
      setData
    );
    fetchData(
      JNTUK_API_Urls.Dual_Degree_Programmes +
        `&filters[Category][$contains]=Dual Degree&pagination[pageSize]=1000`,
      setData2
    );
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Integrated Dual Degree Programmes | JNTU Anantapur</title>
        <meta
          name="description"
          content="Integrated Dual Degree Programmes of Jawaharlal Nehru Technological University Anantapur"
        />
        <meta
          name="keywords"
          content="Integrated Dual Degree Programmes of Jawaharlal Nehru Technological University Anantapur"
        ></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="home"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />
      <style>
        {`
                    body,
                    .rs-latest-events.style1 .event-wrap .events-short .date-part .date,
                    .widget ul li a {
                        font-size: ${fontSize}px;
                    }
                    .rs-inner-blog .blog-item .blog-content .blog-title,
                    .widget h1,.content-wrapper h2, .heading, .college-title{
                        font-size: calc(${fontSize}px + 10px);
                    }
                    .breadcrumb-bg .breadcrumbs-text ul li{
                      font-size: calc(${fontSize}px + 2px);
                    }
                    
                    .full-width-header .rs-header .topbar-area .topbar-right li.login-register a,
                    .readon.orange-btn, .rs-latest-events.bg-wrap .notification-filter button,
                    .rs-latest-events.style1 .event-wrap:not(.ls-area) .events-short .slNo, 
                    .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > a,
                    .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > ul li > a,
                    table th, table td {                       
                        font-size: calc(${fontSize}px - 2px);
                    }

                    .rs-footer .footer-top-social .social_msg{
                        font-size: calc(${fontSize}px + 18px);
                    }
                    .rs-footer .footer-center h3{
                        font-size: calc(${fontSize}px + 12px);
                    }
                    .rs-footer.home9-style .footer-top .widget-title,
                    .rs-team.style1 .about-btn--primary,
                    .rs-team.style1 .about-btn--secondary,
                    .course-overview .inner-box h5{
                        font-size: calc(${fontSize}px + 4px);
                    }
                    .rs-footer .footer-center h4,
                    .rightBar h4{
                        font-size: calc(${fontSize}px + 6px);
                    }
                    
                `}
      </style>
      <div className="pageTopOffset">
        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
          pageTitle="Integrated Dual Degree Programmes"
          pageName="Integrated Dual Degree Programmes"
          breadcrumbsClass="breadcrumb-bg"
        />
        {/* breadcrumb-area-start */}
        {getMenu(currentDomain)}
        <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
          <div className="container">
            <div className="row">
              <div className="col pl-20 md-pl-14">
                <div className="row">
                  <div className="rs-inner-blog col-lg-12">
                    <h3 className="college-title">
                      Integrated Dual Degree Programmes
                    </h3>
                    <div className="overflow-x">
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Degree</th>
                            <th scope="col">Course/Specialization Name</th>
                            <th scope="col">Category</th>
                            <th scope="col">Duration</th>
                            <th scope="col">Course Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.data?.map((obj, index) => (
                            <tr>
                              <td>
                                {(index + 1).toString().padStart(2, "0")}.
                              </td>
                              <td>{obj?.attributes?.Degree}</td>
                              <td>{obj?.attributes?.Course_Name}</td>
                              <td>{obj?.attributes?.Category}</td>
                              <td>{obj?.attributes?.Duration}</td>
                              <td>{obj?.attributes?.Course_Type}</td>
                            </tr>
                          ))}
                          {data?.data?.length === 0 && (
                            <td style={{ textAlign: "center" }} colspan="6">
                              No Courses Found
                            </td>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="overflow-x">
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Degree</th>
                            <th scope="col">Course/Specialization Name</th>
                            <th scope="col">Category</th>
                            <th scope="col">Duration</th>
                            <th scope="col">Course Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data2?.data?.map((obj, index) => (
                            <tr>
                              <td>
                                {(index + 1).toString().padStart(2, "0")}.
                              </td>
                              <td>{obj?.attributes?.Degree}</td>
                              <td>{obj?.attributes?.Course_Name}</td>
                              <td>{obj?.attributes?.Category}</td>
                              <td>{obj?.attributes?.Duration}</td>
                              <td>{obj?.attributes?.Course_Type}</td>
                            </tr>
                          ))}
                          {data2?.data?.length === 0 && (
                            <td style={{ textAlign: "center" }} colspan="6">
                              No Courses Found
                            </td>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Accreditation />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
};

export default Dual_Degree_Programmes;
