import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AcademicsMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import { BlocksRenderer } from "../../components/BlockRenderer";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData } from "../../utils/fetchData";
import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import RightSidebar from "../../components/Common/RightSidebar";

const getChildPath = (type, setPath) => {
  let text = "Circular Aspects";
  if (!type) {
    setPath({ pageCategory: "", pageName: "Circular Aspects" });
    return null;
  }
  if (type === "Circular_Aspects") text = "Circular Aspects";
  else if (type === "Curriculum_Design_and_Development")
    text = "Curriculum Design and Development";
  else if (type === "Curriculum_Enrichment") text = "Curriculum Enrichment";
  setPath({
    pageCategory: { label: "Circular Aspects", path: "academicsdata" },
    pageName: text,
  });
  return null;
};

const ResearchContent = () => {
  const [data, setData] = useState([]);
  const [path, setPath] = useState({
    pageCategory: "",
    pageName: "Circular Aspects",
  });
  const { type } = useParams();
  useEffect(() => {
    getChildPath(type, setPath);
    fetchData(JNTUK_API_Urls.Circular_Aspects, setData);
  }, [type]);

  return (
    <React.Fragment>
      <SiteBreadcrumb
        pageCategory={path.pageCategory}
        pageName={path.pageName}
      />
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 xl-mb-30">
              <div className="about-intro">
                <AcademicsMenu />
                <SideBar />
              </div>
            </div>
            <div className="col-xl-6 pl-20 xl-pl-14">
              <div className="content-wrapper">
                <div className="content-section">
                  <h3 title="Circular Aspects" style={{ marginBottom: "20px" }}>
                    Circular Aspects
                  </h3>
                  {(!type || type === "Circular_Aspects") && (
                    <BlocksRenderer
                      content={data?.data?.attributes?.Circular_Aspects || []}
                    />
                  )}
                  {(!type || type === "Curriculum_Design_and_Development") && (
                    <BlocksRenderer
                      content={
                        data?.data?.attributes
                          ?.Curriculum_Design_and_Development || []
                      }
                    />
                  )}
                  {(!type || type === "Curriculum_Enrichment") && (
                    <BlocksRenderer
                      content={
                        data?.data?.attributes?.Curriculum_Enrichment || []
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 pl-40 xl-pl-14">
              <RightSidebar />
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default ResearchContent;
