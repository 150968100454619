import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import Header from "../../../../components/Layout/Header/Header";
import Footer from "../../../../components/Layout/Footer/Footer";
import ScrollToTop from "../../../../components/Common/ScrollTop";
import OffWrap from "../../../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../../../components/Common/Breadcumb";
import SearchModal from "../../../../components/Layout/Header/SearchModal";
// Image
import footerLogo from "../../../../assets/img/logo/jntua-logo.png";
import { fetchData } from "../../../../utils/fetchData";
import { BlocksRenderer } from "../../../../components/BlockRenderer";
import {
  getAPIPath,
  getMenu,
  getPageTitle,
} from "../../../../utils/getDomainData";
const CommonContact = () => {
  const [data, setData] = useState([]);
  let location = useLocation();
  const currentDomain = location.pathname.split("/")[1];
  useEffect(() => {
    fetchData(getAPIPath(currentDomain)?.contact, setData);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Contact Us - {getPageTitle(currentDomain)}</title>
        <meta name="description" content="" />
        <meta name="keywords" content=""></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="home"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />
      <div className="pageTopOffset">
        <SiteBreadcrumb
          pageTitle="Contact Us"
          pageName="Contact Us"
          breadcrumbsClass="breadcrumb-bg"
        />
        {getMenu(currentDomain)}

        <div
          id="rs-about"
          className="rs-contact style2 style3 pt-50 pb-50 md-pt-30"
        >
          <div className="container">
            <div className="row">
              <div className="col md-mb-30">
                <div className="content-wrapper">
                  <div className="content-section">
                    <h3
                      style={{ marginBottom: "20px" }}
                      title={data?.data?.attributes?.Page_Heading}
                    >
                      {data?.data?.attributes?.Page_Heading}
                    </h3>
                    {
                      <div>
                        <BlocksRenderer
                          content={data?.data?.attributes?.Body || []}
                        />
                      </div>
                    }

                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <a href="/contact-us" target="_blank" className="moreContactInfo">
                  <div className="d-flex align-items-center">
                    <i class="fa-solid fa-phone-office me-2"></i>
                    <div class="flex-fill me-2">
                      Click here for more Contact No's
                    </div>
                    <i class="fa-solid fa-chevron-right"></i>
                  </div>
                </a>
              </div>
              <div className="col-12 pt-10">
                <hr></hr>
                <div className="content-wrapper">
                  <div className="content-section">
                    <iframe
                      title="Location Map"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3860.077813705945!2d77.60589311461557!3d14.651524189770047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb14a555e1d626f%3A0x92d44831356941d1!2sJawaharlal+Nehru+Technological+University+Anantapur!5e0!3m2!1sen!2sus!4v1535200108942"
                      width="100%"
                      height="450"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Accredation /> */}

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup secondaryColor" />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
};

export default CommonContact;
