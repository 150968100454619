import React from "react";
import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";

const Content = () => {

  return (
    <React.Fragment>
      <SiteBreadcrumb
        pageCategory=""
        pageName="Disclaimer"
      />
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="content-wrapper">
                <div className="content-section">
                  <h3 title="Disclaimer">Disclaimer</h3>
                  <hr></hr>
                  <div className="pageContent">
                    <p>
                      Jawaharlal Nehru Technological University, Anantapur has developed the web-site (Jntua.edu.in) with a view to provide information useful to the people concerning the department. Efforts have been made to provide accurate and reliable information on the web-site. However in the case of any errors found in the website we shall make all efforts to correct the errors/mistakes which are brought to our notice in order to keep the web-site updated. In the documents of the web-site there is information from private institution and other people. We have no control on accuracy, relevancy of those information.
                    </p>
                    <p>
                      We do not give any such guarantee also. Information in the web-site is for the benefit of the people. No legal right or responsibility arises there from. All possible care has been exercised for its correctness and reliability. However, JNTUA is not responsible for any inadvertence or any typographical error. If you feel that some information is not true and requires amendment, you may give your opinion for remedial measures. Statements/forms in the web site are taken from both-hard copy and soft copy. Sufficient efforts have been made to correct the mistakes that arises from the conversion of these documents. However, there may still be some mistakes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default Content;
