import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import ScrollToTop from "../../components/Common/ScrollTop";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SearchModal from "../../components/Layout/Header/SearchModal";
import GalleryPart from "./GalleryPart";
import footerLogo from "../../assets/img/logo/jntua-logo.png";
import Accreditation from "../../components/Common/Accreditation";
import { JNTUKHappeningMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import RightSidebar from "../../components/Common/RightSidebar";

const GalleryALL = () => {
  const { state } = useLocation();
  return (
    <React.Fragment>
      <Helmet>
        <title>{state?.type} | JNTU Anantapur</title>
        <meta
          name="description"
          content="Gallery Jawaharlal Nehru Technological University Anantapur"
        />
        <meta
          name="keywords"
          content="Gallery Jawaharlal Nehru Technological University Anantapur"
        ></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="home"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />

      <div className="pageTopOffset">
        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
          pageTitle={state?.type}
          pageName={state?.type}
          breadcrumbsClass="breadcrumb-bg"
        />
        {/* breadcrumb-area-start */}

        <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 xl-mb-30">
                <div className="about-intro">
                  <JNTUKHappeningMenu />
                  <SideBar />
                </div>
              </div>
              <div className="col-xl-6 pl-20 xl-pl-14">
                <div className="row">
                  <div className="rs-inner-blog col-lg-12">
                    <div className="blog-item">
                      <div className="blog-content">
                        <GalleryPart
                          title={state.title || ""}
                          images={state.images}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 pl-40 xl-pl-14">
                <RightSidebar />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Accreditation />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
};

export default GalleryALL;
