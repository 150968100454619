import React from "react";
import { AcademicsMenu } from "../../components/Common/SidebarMenu";
import { Link } from "react-router-dom";
import SideBar from "../../components/Common/SideBar";
import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import RightSidebar from "../../components/Common/RightSidebar";

const SDDContent = () => {
  return (
    <React.Fragment>
      <SiteBreadcrumb pageName="Regulations & Syllabus" />
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 xl-mb-30">
              <div className="about-intro">
                <AcademicsMenu />
                <SideBar />
              </div>
            </div>
            <div className="col-xl-6 pl-20 xl-pl-14">
              <div className="content-wrapper">
                <h3 className="heading" title="Regulations & Syllabus">
                  Regulations & Syllabus
                </h3>
                <div className="content-section">
                  <ul>
                    <li>
                      <Link to="/syllabus/under-graduate-courses">
                        Under-Graduate Courses
                      </Link>
                    </li>
                    <li>
                      <Link to="/syllabus/post-graduate-courses">
                        Post-Graduate Courses
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 pl-40 xl-pl-14">
              <RightSidebar />
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default SDDContent;
