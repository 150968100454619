import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import ScrollToTop from "../../components/Common/ScrollTop";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SearchModal from "../../components/Layout/Header/SearchModal";
import GalleryPart from "./GalleryPart";
import footerLogo from "../../assets/img/logo/jntua-logo.png";
import Accreditation from "../../components/Common/Accreditation";
import { JNTUKHappeningMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { useParams } from "react-router-dom";
import { fetchData } from "../../utils/fetchData";
import RightSidebar from "../../components/Common/RightSidebar";
import { useFontSize } from "../../FontSizeContext";

const Gallery = () => {
  const { fontSize } = useFontSize();
  const [data, setData] = useState([]);
  const { type } = useParams();
  const [title, setTitle] = useState("Gallery");
  useEffect(() => {
    if (type === "academic-events") {
      setTitle("Academic Events");
      fetchData(JNTUK_API_Urls.Academic_Events, setData);
    } else if (type === "festivals") {
      setTitle("Festivals");
      fetchData(JNTUK_API_Urls.Festivals, setData);
    } else if (type === "university-celebrations") {
      setTitle("University Celebrations");
      fetchData(JNTUK_API_Urls.University_Celebrations, setData);
    } else if (type === "sports-events") {
      setTitle("Sports Events");
      fetchData(JNTUK_API_Urls.Sports_Events, setData);
    } else {
      setTitle("Galleries");
      fetchData(JNTUK_API_Urls.University_Gallery, setData);
    }
  }, [type]);
  return (
    <React.Fragment>
      <Helmet>
        <title>{title} | JNTU Anantapur</title>
        <meta
          name="description"
          content="Gallery Jawaharlal Nehru Technological University Anantapur"
        />
        <meta
          name="keywords"
          content="Gallery Jawaharlal Nehru Technological University Anantapur"
        ></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="home"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />
      <style>
        {`
                    body,
                    .content-wrapper .content-section .events-short .date-part .date,
                    .widget ul li a {
                        font-size: ${fontSize}px;
                    }
                    .rs-inner-blog .blog-item .blog-content .blog-title,
                    .widget h1,.content-wrapper h2, .heading, .college-title,
                    .content-wrapper .content-section h3{
                        font-size: calc(${fontSize}px + 10px);
                    }
                    .breadcrumb-bg .breadcrumbs-text ul li,
                    .notficationTitle, h6{
                      font-size: calc(${fontSize}px + 2px);
                    }
                    
                    .full-width-header .rs-header .topbar-area .topbar-right li.login-register a,
                    .readon.orange-btn, .rs-latest-events.bg-wrap .notification-filter button,
                    .rs-latest-events.style1 .event-wrap:not(.ls-area) .events-short .slNo, 
                    .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > a,
                    .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > ul li > a {                       
                        font-size: calc(${fontSize}px - 2px);
                    }

                    .rs-footer .footer-top-social .social_msg{
                        font-size: calc(${fontSize}px + 18px);
                    }
                    .rs-footer .footer-center h3{
                        font-size: calc(${fontSize}px + 12px);
                    }
                    .rs-footer.home9-style .footer-top .widget-title,
                    .content-wrapper .content-section h4{
                        font-size: calc(${fontSize}px + 4px);
                    }
                    .rs-footer .footer-center h4{
                        font-size: calc(${fontSize}px + 6px);
                    }
                    
                `}
      </style>

      <div className="pageTopOffset">
        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
          pageTitle={title}
          pageName={title}
          breadcrumbsClass="breadcrumb-bg"
        />
        {/* breadcrumb-area-start */}

        <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 xl-mb-30">
                <div className="about-intro">
                  <JNTUKHappeningMenu />
                  <SideBar />
                </div>
              </div>
              <div className="col-xl-6 pl-20 xl-pl-14">
                <div className="row">
                  <div className="rs-inner-blog col-lg-12">
                    <div className="blog-item">
                      <div className="blog-content">
                        <h3 className="blog-title" title={title}>{title}</h3>
                        {data?.data?.length === 0 && (
                          <p>{title} data not available.</p>
                        )}
                        {data?.data?.map((obj) => (
                          <GalleryPart
                            title={obj?.attributes?.Gallery_Title || ""}
                            images={obj?.attributes?.Images}
                            type={title}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 pl-40 xl-pl-14">
                <RightSidebar />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Accreditation />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
};

export default Gallery;
