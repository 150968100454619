import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import ScrollToTop from "../../components/Common/ScrollTop";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SearchModal from "../../components/Layout/Header/SearchModal";
import footerLogo from "../../assets/img/logo/jntua-logo.png";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData } from "../../utils/fetchData";
import Accreditation from "../../components/Common/Accreditation";
import { AboutMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import RightSidebar from "../../components/Common/RightSidebar";
import Pagination from "../../components/Pagination";
import { useFontSize } from "../../FontSizeContext";
import { useParams } from "react-router-dom";

const getChildPath = (type, setPath, setFilter) => {
  let text = "Contact Us";
  if (!type) {
    setPath({ pageCategory: "", pageName: "Contact Us" });
    setFilter("");
    return null;
  }
  if (type === "university-officials") {
    text = "Contact Details of University Officials";
    setFilter("Contact Details of University Officials");
  } else if (type === "examination-branch") {
    text = "Examination Branch";
    setFilter("Examination Branch");
  } else if (type === "engineering-cell") {
    text = "Engineering Cell";
    setFilter("Engineering Cell");
  } else if (type === "co-ordinators") {
    text = "Co-Ordinators";
    setFilter("Co-Ordinators");
  } else if (type === "sports-council") {
    text = "Sports Council";
    setFilter("Sports Council");
  } else if (type === "pro-peshi") {
    text = "PRO/Peshi";
    setFilter("PRO/Peshi");
  } else if (type === "administrative-office-staff") {
    text = "Administrative Office Staff";
    setFilter("Administrative Office Staff");
  }
  setPath({
    pageCategory: { label: "Contact Us", path: "contact-us" },
    pageName: text,
  });
  return null;
};
const JNTUA_Contacts = () => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [path, setPath] = useState({
    pageCategory: "",
    pageName: "Contact Us",
  });
  const [filter, setFilter] = useState("");
  const { type } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(25);
  const totalItems = data?.meta?.pagination?.total;
  const { fontSize } = useFontSize();
  useEffect(() => {
    getChildPath(type, setPath, setFilter);
  }, [type]);
  useEffect(() => {
    if (type === "pro-peshi") {
      fetchData(
        JNTUK_API_Urls.JNTUA_Contacts +
          `&filters[Type][$contains]=PRO` +
          `&pagination[page]=${currentPage}`,
        setData
      );
      fetchData(
        JNTUK_API_Urls.JNTUA_Contacts +
          `&filters[Type][$contains]=Peshi` +
          `&pagination[page]=${currentPage}`,
        setData2
      );
    } else {
      fetchData(
        JNTUK_API_Urls.JNTUA_Contacts +
          `&filters[Type][$contains]=${filter}` +
          `&pagination[page]=${currentPage}`,
        setData
      );
    }
  }, [currentPage, filter]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <React.Fragment>
      <Helmet>
        <title>Contact Us | JNTU Anantapur</title>
        <meta
          name="description"
          content="Executive Council of Jawaharlal Nehru Technological University Anantapur"
        />
        <meta
          name="keywords"
          content="Executive Council of Jawaharlal Nehru Technological University Anantapur"
        ></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="home"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />
      <style>
        {`
                    body,
                    .rs-latest-events.style1 .event-wrap .events-short .date-part .date,
                    .widget ul li a {
                        font-size: ${fontSize}px;
                    }
                    .rs-inner-blog .blog-item .blog-content .blog-title,
                    .widget h1,.content-wrapper h2, .heading, .college-title{
                        font-size: calc(${fontSize}px + 10px);
                    }
                    .breadcrumb-bg .breadcrumbs-text ul li{
                      font-size: calc(${fontSize}px + 2px);
                    }
                    
                    .full-width-header .rs-header .topbar-area .topbar-right li.login-register a,
                    .readon.orange-btn, .rs-latest-events.bg-wrap .notification-filter button,
                    .rs-latest-events.style1 .event-wrap:not(.ls-area) .events-short .slNo, 
                    .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > a,
                    .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > ul li > a,
                    table th, table td {                       
                        font-size: calc(${fontSize}px - 2px);
                    }

                    .rs-footer .footer-top-social .social_msg{
                        font-size: calc(${fontSize}px + 18px);
                    }
                    .rs-footer .footer-center h3{
                        font-size: calc(${fontSize}px + 12px);
                    }
                    .rs-footer.home9-style .footer-top .widget-title,
                    .rs-team.style1 .about-btn--primary,
                    .rs-team.style1 .about-btn--secondary,
                    .course-overview .inner-box h5{
                        font-size: calc(${fontSize}px + 4px);
                    }
                    .rs-footer .footer-center h4,
                    .rightBar h4{
                        font-size: calc(${fontSize}px + 6px);
                    }
                    
                `}
      </style>
      <div className="pageTopOffset">
        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
          pageCategory={path.pageCategory}
          pageName={path.pageName}
        />
        {/* breadcrumb-area-start */}

        <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 xl-mb-30">
                <div className="about-intro">
                  <AboutMenu />
                  <SideBar />
                </div>
              </div>
              <div className="col-xl-6 pl-20 xl-pl-14">
                <div className="row">
                  {type !== "pro-peshi" ? (
                    <div className="rs-inner-blog col-lg-12">
                      <h3 className="college-title">{path?.pageName}</h3>
                      <div className="overflow-x">
                        <table className="table table-striped table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">Designation</th>
                              <th scope="col">Phone/Fax</th>
                              <th scope="col">E-mail Id</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.data?.map((obj, index) => (
                              <tr>
                                <td>{obj?.attributes?.Designation}</td>
                                <td>{obj?.attributes?.Phone_Fax}</td>
                                <td>{obj?.attributes?.Email_Id}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <Pagination
                        currentPage={currentPage}
                        paginate={paginate}
                        arrayLength={totalItems / itemsPerPage}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="rs-inner-blog col-lg-12">
                        <h3 className="college-title">PRO</h3>
                        <div className="overflow-x">
                          <table className="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">Designation</th>
                                <th scope="col">Phone/Fax</th>
                                <th scope="col">E-mail Id</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.data?.map((obj, index) => (
                                <tr>
                                  <td>{obj?.attributes?.Designation}</td>
                                  <td>{obj?.attributes?.Phone_Fax}</td>
                                  <td>{obj?.attributes?.Email_Id}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="rs-inner-blog col-lg-12">
                        <h3 className="college-title">Peshi</h3>
                        <div className="overflow-x">
                          <table className="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">Designation</th>
                                <th scope="col">Phone/Fax</th>
                                <th scope="col">E-mail Id</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data2?.data?.map((obj, index) => (
                                <tr>
                                  <td>{obj?.attributes?.Designation}</td>
                                  <td>{obj?.attributes?.Phone_Fax}</td>
                                  <td>{obj?.attributes?.Email_Id}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <Pagination
                          currentPage={currentPage}
                          paginate={paginate}
                          arrayLength={totalItems / itemsPerPage}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-xl-3 pl-40 xl-pl-14">
                <RightSidebar />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Accreditation />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
};

export default JNTUA_Contacts;
