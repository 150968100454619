import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Accreditation from "../../components/Common/Accreditation";
import footerLogo from "../../assets/img/logo/jntua-logo.png";
import Content from "./Content";
import { useFontSize } from '../../FontSizeContext';

const WebPolicy = () => {
  const { fontSize } = useFontSize();
  return (
    <React.Fragment>
        <Helmet>
        <title>Website Privacy Policy | JNTU Anantapur</title>
        <meta name="description" content="Website Privacy Policy Jawaharlal Nehru Technological University Anantapur" />
        <meta name="keywords" content="Website Privacy Policy Jawaharlal Nehru Technological University Anantapur"></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="home"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />
       <style>
        {`
                    body{
                        font-size: ${fontSize}px;
                    }
                    .rs-inner-blog .blog-item .blog-content .blog-title,
                    .widget h1,.content-wrapper h2, .heading, .college-title,
                    .content-wrapper .content-section h3{
                        font-size: calc(${fontSize}px + 10px);
                    }
                    .breadcrumb-bg .breadcrumbs-text ul li{
                      font-size: calc(${fontSize}px + 2px);
                    }
                    
                    .full-width-header .rs-header .topbar-area .topbar-right li.login-register a,
                    .readon.orange-btn, .rs-latest-events.bg-wrap .notification-filter button,
                    .rs-latest-events.style1 .event-wrap:not(.ls-area) .events-short .slNo, 
                    .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > a,
                    .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > ul li > a {                       
                        font-size: calc(${fontSize}px - 2px);
                    }

                    .rs-footer .footer-top-social .social_msg{
                        font-size: calc(${fontSize}px + 18px);
                    }
                    .rs-footer .footer-center h3{
                        font-size: calc(${fontSize}px + 12px);
                    }
                    .rs-footer.home9-style .footer-top .widget-title,
                    .content-wrapper .content-section h4{
                        font-size: calc(${fontSize}px + 4px);
                    }
                    .rs-footer .footer-center h4{
                        font-size: calc(${fontSize}px + 6px);
                    }
                    
                `}
      </style>
      <div className="pageTopOffset">
        <Content />
      </div>

      <Accreditation />
      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <SearchModal />
    </React.Fragment>
  );
};

export default WebPolicy;
