import React from "react";
import { imageBaseURL } from "../../../utils/fetchData";

function RightSidebar({ data }) {
  return (
    <div className="rightBar">
      <div className="rBlock">
        {data?.data?.attributes?.Right_Image1?.data?.attributes?.url && (
          <>
            <img
              src={
                `${imageBaseURL}${data?.data?.attributes?.Right_Image1?.data?.attributes?.url}` ||
                ""
              }
              alt="Right Side Image1"
              title="Right Side Image1"
              className="w-100 mb-10"
            />
            <h4>{data?.data?.attributes?.Right_Image1_Title || ""}</h4>
            <p>{data?.data?.attributes?.Right_Image1_Description || ""}</p>
          </>
        )}
      </div>
      {data?.data?.attributes?.Right_Image2?.data?.attributes?.url && (
        <>
          <div className="rBlock">
            <img
              src={
                `${imageBaseURL}${data?.data?.attributes?.Right_Image2?.data?.attributes?.url}` ||
                ""
              }
              alt="Right Side Image2"
              title="Right Side Image2"
              className="w-100 mb-10"
            />
            <h4>{data?.data?.attributes?.Right_Image2_Title || ""}</h4>
            <p>{data?.data?.attributes?.Right_Image2_Description || ""}</p>
          </div>
        </>
      )}
    </div>
  );
}

export default RightSidebar;
