import React from "react";
import { Link } from "react-router-dom";
import jntua from '../../assets/img/constituent-units/jntua.jpg';
import jntuap from '../../assets/img/constituent-units/jntua-p.jpg';
import jntuak from '../../assets/img/constituent-units/jntua-k.jpg';
import jntuaoa from '../../assets/img/constituent-units/jntua-o-a.jpg';
import mba1 from '../../assets/img/constituent-units/mba-1.jpg';

const ConstituentUnits = () => {

    return (
        <div className="bg-wrap md-pb-30" style={{background: "#fcfaed"}}>
            <div className='divider mb-30'></div>
            <div className="container">
                <div className="row justify-content-center row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-5">
                    <div className="mb-30 text-center">
                        <Link >
                            <img src={jntua} alt="JNTUA College of Engg, Ananthapuramu" title="JNTUA College of Engg, Ananthapuramu" className="border border-dark"/>
                            <strong className="d-block">
                                JNTUA College of Engg, Ananthapuramu
                            </strong>
                        </Link>
                    </div>
                    <div className="mb-30 text-center">
                        <Link >
                            <img src={jntuap} alt="JNTUA College of Engg, Pulivendula" title="JNTUA College of Engg, Pulivendula"  className="border border-dark"/>
                            <strong className="d-block">
                                JNTUA College of Engg, Pulivendula
                            </strong>
                        </Link>
                    </div>
                    <div className="mb-30 text-center">
                        <Link >
                            <img src={jntuak} alt="JNTUA College of Engg, Kalkiri" title="JNTUA College of Engg, Kalkiri"  className="border border-dark"/>
                            <strong className="d-block">
                                JNTUA College of Engg, Kalkiri
                            </strong>
                        </Link>
                    </div>
                    <div className="mb-30 text-center">
                        <Link >
                            <img src={jntuaoa} alt="JNTUA - OTPRI, Ananthapuramu" title="JNTUA - OTPRI, Ananthapuramu"  className="border border-dark"/>
                            <strong className="d-block">
                                JNTUA - OTPRI, Ananthapuramu
                            </strong>
                        </Link>
                    </div>
                    <div className="mb-30 text-center">
                        <Link >
                            <img src={mba1} alt="JNTUA School of Management Studies" title="JNTUA School of Management Studies"  className="border border-dark"/>
                            <strong className="d-block">
                                JNTUA School of Management Studies
                            </strong>
                        </Link>
                    </div>
                </div>

            </div>
            <div className='divider'></div>
        </div>
    );
};

export default ConstituentUnits;
