import React from 'react';
import { Helmet } from 'react-helmet';
import OffWrap from '../../components/Layout/Header/OffWrap';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import HomeMain from './HomeMain';

import footerLogo from '../../assets/img/logo/Jntuk-white-logo.png';
import { useFontSize } from '../../FontSizeContext';

const HomePage = () => {
    const { fontSize } = useFontSize();
    return (
        <React.Fragment>
            <Helmet>
                <title>Jawaharlal Nehru Technological University, Anantapur</title>
                <meta name="description" content="Jawaharlal Nehru Technological University, Anantapur is a public university, in Anantapur, East Godavari district, in Andhra Pradesh state" />
                <meta name="keywords" content="JNTU Anantapur, Jawaharlal Nehru Technological University Anantapur"></meta>
            </Helmet>
            <OffWrap />
            <Header
                parentMenu='home'
                TopBar='enable'
                TopBarClass="topbar-area dark-primary-bg hidden-lg"
            />
            <style>
                {`
                    body,
                    .rs-latest-events.style1 .event-wrap .events-short .date-part .date,
                    .site-wrap h4 {
                        font-size: ${fontSize}px;
                    }
                    .sec-title .sub-title,
                    .rs-about.style1 .info-part h2{
                        font-size: calc(${fontSize}px + 16px);
                    }
                    .rs-slider.main-home .fixedBannerTitle .content-part .sl-title{
                        font-size: calc(${fontSize}px + 56px);
                    }
                    .rs-slider.main-home .fixedBannerTitle .content-part .sl-sub-title{
                        font-size: calc(${fontSize}px + 26px);
                    }
                    .full-width-header .rs-header .topbar-area .topbar-right li.login-register a,
                    .readon.orange-btn, .rs-latest-events.bg-wrap .notification-filter button,
                    .rs-latest-events.style1 .event-wrap:not(.ls-area) .events-short .slNo, 
                    .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > a,
                    .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > ul li > a,
                    .rs-latest-events.style1 .event-wrap:not(.ls-area) .events-short .date-part .date,
                    .rs-latest-events.bg-wrap .index-container .viewall-notification,
                    .rs-latest-events.bg-wrap .index-container .index-item {                       
                        font-size: calc(${fontSize}px - 2px);
                    }
                    .rs-footer .footer-top-social .social_msg{
                        font-size: calc(${fontSize}px + 18px);
                    }
                    .rs-footer .footer-center h3{
                        font-size: calc(${fontSize}px + 12px);
                    }
                    .rs-footer.home9-style .footer-top .widget-title{
                        font-size: calc(${fontSize}px + 4px);
                    }
                    .rs-footer .footer-center h4{
                        font-size: calc(${fontSize}px + 6px);
                    }
                    @media only screen and (max-width: 767px) {
                        .sec-title .sub-title {
                            font-size: calc(${fontSize}px + 2px);
                        }
                    }
                    @media (max-width: 575px){
                        .rs-about.style1 .info-part h2 {
                            font-size: calc(${fontSize}px + 8px);
                        }
                    }
                    @media only screen and (max-width: 480px) {
                        .rs-slider.main-home .slider-content .content-part .sl-sub-title {
                            font-size: calc(${fontSize}px + 2px);
                        }
                    
                        .rs-slider.main-home .slider-content .content-part .sl-title {
                            font-size: calc(${fontSize}px + 16px) ;
                        }
                    }
                `}
            </style>
            <HomeMain />
            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />
            <SearchModal />
        </React.Fragment>
    );
}

export default HomePage;
