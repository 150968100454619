import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import ScrollToTop from "../../components/Common/ScrollTop";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SearchModal from "../../components/Layout/Header/SearchModal";
import footerLogo from "../../assets/img/logo/jntua-logo.png";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData } from "../../utils/fetchData";
import Accreditation from "../../components/Common/Accreditation";
import { AcademicsMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import { BlocksRenderer } from "../../components/BlockRenderer";
import RightSidebar from "../../components/Common/RightSidebar";
import Pagination from "../../components/Pagination";
import { useFontSize } from "../../FontSizeContext";
import { useParams } from "react-router-dom";
const getChildPath = (type, setPath, setFilter, setUrl) => {
  let text = "Contact Us";
  if (!type) {
    setPath({ pageCategory: "", pageName: "Programmes Offered" });
    setUrl("");
    setFilter("");
    return null;
  }
  if (type === "jntua-cea-programs-offered") {
    text = "JNTUA College of Engineering, Ananthapuramu ( C E A )";
    setFilter("JNTUA CEA");
    setUrl("https://www.jntuacea.ac.in/");
  } else if (type === "jntua-cep-programs-offered") {
    text = "JNTUA College of Engineering, Pulivendula ( C E P )";
    setFilter("JNTUA CEP");
    setUrl("https://www.jntuacep.ac.in/");
  } else if (type === "jntua-cek-programs-offered") {
    text = "JNTUA College of Engineering, Kalikiri ( C E K )";
    setFilter("JNTUA CEK");
    setUrl("https://www.jntuacek.ac.in/");
  } else if (type === "jntua-otpri") {
    text = "JNTUA – OTPRI, Ananthapuramu";
    setFilter("JNTUA OTPRI");
    setUrl("https://www.jntuaotpri.ac.in/");
  } else if (type === "jntua-sms") {
    text = "JNTUA – School of Management Studies ( S M S ), Ananthapuramu";
    setFilter("JNTUA SMS");
    setUrl("https://www.jntuasms.ac.in/");
  } else if (type === "research-programs") {
    text = "JNTUA Research Programs, Ananthapuramu";
    setFilter("JNTUA Research Programs");
    setUrl("");
  }
  setPath({
    pageCategory: { label: "Programmes Offered", path: "programmes-offered" },
    pageName: text,
  });
  return null;
};
const ProgrammesOfferedList = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [path, setPath] = useState({
    pageCategory: "",
    pageName: "Programmes Offered",
  });
  const [filter, setFilter] = useState("");
  const { type } = useParams();
  const [url, setUrl] = useState("");
  const [itemsPerPage] = useState(25);
  const totalItems = data?.meta?.pagination?.total;
  const { fontSize } = useFontSize();
  useEffect(() => {
    getChildPath(type, setPath, setFilter, setUrl);
  }, [type]);
  useEffect(() => {
    fetchData(
      JNTUK_API_Urls.Programmes_Offered +
        `?pagination[page]=${currentPage}&filters[Category][$contains]=${filter}`,
      setData
    );
  }, [currentPage, filter]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <React.Fragment>
      <Helmet>
        <title>Programmes Offered | JNTU Anantapur</title>
        <meta
          name="description"
          content="Programmes Offered at Jawaharlal Nehru Technological University Anantapur"
        />
        <meta
          name="keywords"
          content="Programmes Offered at Jawaharlal Nehru Technological University Anantapur"
        ></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="home"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />
      <style>
        {`
                    body,
                    .rs-latest-events.style1 .event-wrap .events-short .date-part .date,
                    .widget ul li a {
                        font-size: ${fontSize}px;
                    }
                    .rs-inner-blog .blog-item .blog-content .blog-title,
                    .widget h1,.content-wrapper h2, .heading, .college-title,
                    .content-wrapper .content-section h3{
                        font-size: calc(${fontSize}px + 10px);
                    }
                    .breadcrumb-bg .breadcrumbs-text ul li{
                      font-size: calc(${fontSize}px + 2px);
                    }
                    
                    .full-width-header .rs-header .topbar-area .topbar-right li.login-register a,
                    .readon.orange-btn, .rs-latest-events.bg-wrap .notification-filter button,
                    .rs-latest-events.style1 .event-wrap:not(.ls-area) .events-short .slNo, 
                    .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > a,
                    .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > ul li > a,
                    table th, table td {                       
                        font-size: calc(${fontSize}px - 2px);
                    }

                    .rs-footer .footer-top-social .social_msg{
                        font-size: calc(${fontSize}px + 18px);
                    }
                    .rs-footer .footer-center h3{
                        font-size: calc(${fontSize}px + 12px);
                    }
                    .rs-footer.home9-style .footer-top .widget-title,
                    .rs-team.style1 .about-btn--primary,
                    .rs-team.style1 .about-btn--secondary,
                    .course-overview .inner-box h5{
                        font-size: calc(${fontSize}px + 4px);
                    }
                    .rs-footer .footer-center h4,
                    .rightBar h4{
                        font-size: calc(${fontSize}px + 6px);
                    }
                    
                `}
      </style>
      <div className="pageTopOffset">
        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
          pageCategory={path.pageCategory}
          pageName={path.pageName}
        />
        {/* breadcrumb-area-start */}

        <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 xl-mb-30">
                <div className="about-intro">
                  <AcademicsMenu />
                  <SideBar />
                </div>
              </div>
              <div className="col-xl-6 pl-20 xl-pl-14">
                <div className="row">
                  <div className="rs-inner-blog col-lg-12">
                    <h3 className="college-title" title={path?.pageName || ""}>
                      {path?.pageName}
                    </h3>
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">S.No</th>
                          <th scope="col">Department</th>
                          <th scope="col">Type</th>
                          <th scope="col">Programme</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.data?.map((obj, index) => (
                          <tr>
                            <td>
                              {((currentPage - 1) * itemsPerPage + index + 1)
                                .toString()
                                .padStart(2, "0")}
                              .
                            </td>
                            <td>{obj?.attributes?.Department}</td>
                            <td>{obj?.attributes?.Type}</td>
                            <td>
                              <BlocksRenderer
                                content={obj?.attributes?.Programme}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Pagination
                      currentPage={currentPage}
                      paginate={paginate}
                      arrayLength={totalItems / itemsPerPage}
                    />
                    {url?.length > 0 && (
                      <p style={{ marginTop: "30px" }}>
                        For more details click here:{" "}
                        <a href={url} target="_blank" rel="noreferrer">
                          {url}
                        </a>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 pl-40 xl-pl-14">
                <RightSidebar />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Accreditation />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
};

export default ProgrammesOfferedList;
