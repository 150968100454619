import React from "react";
import { Helmet } from "react-helmet";
import OffWrap from "../../../../components/Layout/Header/OffWrap";
import Header from "../../../../components/Layout/Header/Header";
import Footer from "../../../../components/Layout/Footer/Footer";
import SearchModal from "../../../../components/Layout/Header/SearchModal";
import HomeMain from "./HomeMain";

import footerLogo from "../../../../assets/img/logo/jntua-logo.png";
import { getPageTitle } from "../../../../utils/getDomainData";

const CommonHome = () => {
  const currentDomain = process.env.REACT_APP_SUB_DOMAIN;
  return (
    <React.Fragment>
      <Helmet>
        <title>{getPageTitle()}</title>
        <meta name="description" content="" />
        <meta name="keywords" content=""></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="home"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />
      <HomeMain currentDomain={currentDomain} />
      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <SearchModal />
    </React.Fragment>
  );
};
export default CommonHome;
