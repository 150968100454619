import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import DAP from "../../assets/img/directorates/DAP.png";
import DAPthumb from "../../assets/img/directorates/thumb/DAP.png";
import DDA from "../../assets/img/directorates/DDA.png";
import DDAthumb from "../../assets/img/directorates/thumb/DDA.png";
import DE from "../../assets/img/directorates/DE.png";
import DEthumb from "../../assets/img/directorates/thumb/DE.png";
import RD from "../../assets/img/directorates/R&D.png";
import RDthumb from "../../assets/img/directorates/thumb/R&D.png";
import DIRP from "../../assets/img/directorates/DIR&P.png";
import DIRPthumb from "../../assets/img/directorates/thumb/DIR&P.png";
import DICS from "../../assets/img/directorates/DICS.png";
import DICSthumb from "../../assets/img/directorates/thumb/DICS.png";
import DFAA from "../../assets/img/directorates/DFA&A.png";
import DFAAthumb from "../../assets/img/directorates/thumb/DFA&A.png";
import DFD from "../../assets/img/directorates/DFD.png";
import DFDthumb from "../../assets/img/directorates/thumb/DFD.png";
import SDC from "../../assets/img/directorates/SDC.png";
import SDCthumb from "../../assets/img/directorates/thumb/SDC.png";
import WEC from "../../assets/img/directorates/WEC.png";
import WECthumb from "../../assets/img/directorates/thumb/WEC.png";
import IQAC from "../../assets/img/directorates/IQAC.png";
import IQACthumb from "../../assets/img/directorates/thumb/IQAC.png";
import OTPRI from "../../assets/img/directorates/OTPRI.png";
import OTPRIthumb from "../../assets/img/directorates/thumb/OTPRI.png";
import SMS from "../../assets/img/directorates/SMS.png";
import SMSthumb from "../../assets/img/directorates/thumb/SMS.png";
import { imageBaseURL } from "../../utils/fetchData";
import { JNTUK_API_Urls } from "../../utils/api.config";
import axios from "../../utils/axios.config";
const departments = [
  {
    department: "Academic & Planning",
    url: JNTUK_API_Urls.JNTUK_DAP_Department,
  },
  { department: "Academic Audit", url: JNTUK_API_Urls.JNTUK_DAA_Department },
  { department: "Admissions", url: JNTUK_API_Urls.JNTUK_DA_Department },
  { department: "Evaluation", url: JNTUK_API_Urls.JNTUK_DE_Department },
  {
    department: "Research & Development",
    url: JNTUK_API_Urls.JNTUK_DRD_Department,
  },
  {
    department: "Industrial Relations & Placements",
    url: JNTUK_API_Urls.DIRP_Department,
  },
  {
    department: "Industrial Consultancy Services",
    url: JNTUK_API_Urls.DICS_Department,
  },
  {
    department: "Foreign Affairs & Alumni Matters",
    url: JNTUK_API_Urls.DFAAM_Department,
  },
  { department: "Faculty Development", url: JNTUK_API_Urls.DFD_Department },
  {
    department: "Internal Quality Assurance Cell",
    url: JNTUK_API_Urls.DIQAC_Department,
  },
  {
    department: "Software Development Centre",
    url: JNTUK_API_Urls.DSDC_Department,
  },
  // { department: "Sponsored Research", url: JNTUK_API_Urls.DSR },
  {
    department: "Women Empowerment Cell",
    url: JNTUK_API_Urls.DWEC_Department,
  },

  {
    department: "Oil Technological & Pharmaceutical Research Institute",
    url: JNTUK_API_Urls.DOTPRI_Department,
  },
  {
    department: "School of Management Studies",
    url: JNTUK_API_Urls.JNTUK_SOMS_Department,
  },
];
const Directorates = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const dataArray = await Promise.all(
        departments?.map(async (dep) => {
          const obj = await axios.get(dep?.url);

          const objNow = obj?.data?.data;
          objNow.department = dep?.department;
          return objNow;
        })
      );
      if (dataArray) setData(dataArray);
    };

    fetchData();
  }, [departments]);

  function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        type="button"
        onClick={onClick}
        className={className}
        title="Next"
        aria-label="Next"
      >
        <i className="fa fa-chevron-right"></i>
      </button>
    );
  }

  function PrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        type="button"
        onClick={onClick}
        className={className}
        title="Previous"
        aria-label="Previous"
      >
        {" "}
        <i className="fa fa-chevron-left"></i>
      </button>
    );
  }

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const slilderSettings = {
    dots: false,
    centerMode: false,
    autoplay: false,
    infinite: false,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: "mainSlider",
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: nav2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const navSliderSettings = {
    dots: false,
    centerMode: false,
    autoplay: false,
    infinite: false,
    arrows: false,
    slidesToShow: 13,
    slidesToScroll: 1,
    className: "navigationSlider",
    focusOnSelect: true,
    asNavFor: nav1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };



  return (
    <div className="bg-wrap md-pb-30">
      <div className="divider mb-30"></div>
      <div className="container">
        <div className="pb-50">
          <div className="sec-title">
            <p className="desc big mb-10 purple-color text-center">
              Find Out More About
            </p>
          </div>
          <div className="sec-title">
            <h2
              className="sub-title orange text-center"
              title="JNTUA DIRECTORATE’S"
            >
              JNTUA DIRECTORATE’S
            </h2>
          </div>
        </div>
        <Slider ref={(slider) => (sliderRef1 = slider)} {...slilderSettings}>
          {data?.map((item, index) => (
            <div key={`MainSlider-${index}`}>
              <div className="row align-items-center">
                <div className="col-xl-5 d-none d-xl-block">
                  {/* <img src={DAP} alt="Banner 1" /> */}
                  <img src={`${item?.department === "Academic & Planning" ? (
                    DAP
                  ) : item?.department === "Academic Audit" ? (
                    DDA
                  ) : item?.department === "Admissions" ? (
                    DDA
                  ) : item?.department === "Evaluation" ? (
                    DE
                  ) : item?.department === "Research & Development" ? (
                    RD
                  ) : item?.department === "Industrial Relations & Placements" ? (
                    DIRP
                  ) : item?.department === "Industrial Consultancy Services" ? (
                    DICS
                  ) : item?.department === "Foreign Affairs & Alumni Matters" ? (
                    DFAA
                  ) : item?.department === "Faculty Development" ? (
                    DFD
                  ) : item?.department === "Internal Quality Assurance Cell" ? (
                    IQAC
                  ) : item?.department === "Software Development Centre" ? (
                    SDC
                  ) : item?.department === "Women Empowerment Cell" ? (
                    WEC
                  ) : item?.department === "Oil Technological & Pharmaceutical Research Institute" ? (
                    OTPRI
                  ) : (
                    SMS
                  )}`} alt={`Director of ${item?.department}`} title={`Director of ${item?.department}`} />
                </div>
                <div className="col-xl-6 offset-xl-1">
                  <div className="text-center dirDetail">
                    <img
                      src={
                        imageBaseURL +
                        item?.attributes?.jntuk_directorate?.data?.attributes
                          ?.Image?.data?.attributes?.url || ""
                      }
                      alt={
                        item?.attributes?.jntuk_directorate?.data?.attributes
                          ?.Name
                      }
                      title={
                        item?.attributes?.jntuk_directorate?.data?.attributes
                          ?.Name
                      }
                    />
                    <h3>
                      {
                        item?.attributes?.jntuk_directorate?.data?.attributes
                          ?.Name
                      }
                    </h3>
                    <h4>Director of {item?.department}</h4>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <Slider ref={(slider) => (sliderRef2 = slider)} {...navSliderSettings}>
          {data?.map((item, index) => (
            <div key={`NavSlider-${index}`} className="slideNav">
              {/* <img
                src={
                  imageBaseURL +
                  item?.attributes?.jntuk_directorate?.data?.attributes?.Image
                    ?.data?.attributes?.url || ""
                }
                alt={
                  item?.attributes?.jntuk_directorate?.data?.attributes?.Name
                }
                title={item?.attributes?.Name}
              /> */}
              <img src={`${item?.department === "Academic & Planning" ? (
                DAPthumb
              ) : item?.department === "Academic Audit" ? (
                DDAthumb
              ) : item?.department === "Admissions" ? (
                DDAthumb
              ) : item?.department === "Evaluation" ? (
                DEthumb
              ) : item?.department === "Research & Development" ? (
                RDthumb
              ) : item?.department === "Industrial Relations & Placements" ? (
                DIRPthumb
              ) : item?.department === "Industrial Consultancy Services" ? (
                DICSthumb
              ) : item?.department === "Foreign Affairs & Alumni Matters" ? (
                DFAAthumb
              ) : item?.department === "Faculty Development" ? (
                DFDthumb
              ) : item?.department === "Internal Quality Assurance Cell" ? (
                IQACthumb
              ) : item?.department === "Software Development Centre" ? (
                SDCthumb
              ) : item?.department === "Women Empowerment Cell" ? (
                WECthumb
              ) : item?.department === "Oil Technological & Pharmaceutical Research Institute" ? (
                OTPRIthumb
              ) : (
                SMSthumb
              )}`} alt={`Director of ${item?.department}`} title={`Director of ${item?.department}`} />

              <p>
                {/* {item?.attributes?.jntuk_directorate?.data?.attributes?.Name ||"sort"} */}
                {item?.department === "Academic & Planning" ? (
                  <>
                    DAP
                  </>
                ) : item?.department === "Academic Audit" ? (
                  <>
                    DAA
                  </>
                ) : item?.department === "Admissions" ? (
                  <>
                    DA
                  </>
                ) : item?.department === "Evaluation" ? (
                  <>
                    DE
                  </>
                ) : item?.department === "Research & Development" ? (
                  <>
                    R&D
                  </>
                ) : item?.department === "Industrial Relations & Placements" ? (
                  <>
                    DIR&P
                  </>
                ) : item?.department === "Industrial Consultancy Services" ? (
                  <>
                    DICS
                  </>
                ) : item?.department === "Foreign Affairs & Alumni Matters" ? (
                  <>
                    DFA&A
                  </>
                ) : item?.department === "Faculty Development" ? (
                  <>
                    DFD
                  </>
                ) : item?.department === "Internal Quality Assurance Cell" ? (
                  <>
                    IQAC
                  </>
                ) : item?.department === "Software Development Centre" ? (
                  <>
                    SDC
                  </>
                ) : item?.department === "Women Empowerment Cell" ? (
                  <>
                    WEC
                  </>
                ) : item?.department === "Oil Technological & Pharmaceutical Research Institute" ? (
                  <>
                    OTPRI
                  </>
                ) : (
                  <>
                    SMS
                  </>
                )}
              </p>
            </div>
          ))}
        </Slider>
      </div>
      <div className="divider"></div>
    </div>
  );
};

export default Directorates;
